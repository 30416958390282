import "./Prototype_Page.css"
import { Image } from "react-bootstrap"

import img1 from "../Content/59/39_لوحة إقرأ/لوحة اقرأ.jpg"

function Page59() {
  
  return (
    <div className="container py-4 page">
      
      <Image src={img1} className="w-100"/>
      
    </div>
  )
}

export default Page59