import "./Prototype_Page.css"
import { Image } from "react-bootstrap"
import Player from "../Main_Comp/VideoPlayer"
import img1 from "../Content/68/45_فلم حلم ملك/غلاف فيديو حلم ملك.bmp"

function Page68() {
      
  return (
    <div className="container py-4 page">
        <Player src={'927933185?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write'}/>
        <hr />
        <h1 className="text-center">غلاف فيديو حلم ملك</h1>
        <Image src={img1} className="w-100"/>
        
    </div>
  )
}

export default Page68