import React from 'react';
// import { Player } from 'video-react';
// import ReactPlayer from 'react-player';
const VideoPlayer = ({src}) => {
    // const options = {
    //     height: '390',
    //     width: '640',
    //     playerVars: {
    //       autoplay: 1,
    //       controls: 1,
    //     }  }
    //     const onReady = (event)=> {
    //         event.target.pauseVideo();
    //       }
    return (
      <div className="d-flex justify-content-center video-responsive">
      <iframe
        src={`https://player.vimeo.com/video/${src}`}
        width="560"
        height="315"
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
        title="Vimeo video player"
      ></iframe>
    </div>
    )
        // <div className="embed-responsive embed-responsive-16by9 w-100">
        // <video controls className="embed-responsive-item w-100">
        //     <source src={src} type="video/webm" />
        //     Your browser does not support the video tag.
        // </video>
        // </div>
        // <Player>
        //     <source src={src} />
        // </Player>  

        // <ReactPlayer
        // url={src}
        // controls={true}
        // width="100%"
        // height="auto"
        // />   
    
}

export default VideoPlayer;
