
import { Container,Col,Row } from 'react-bootstrap';


const Pdf=({src,title})=>{

  return(
    <Container>
      <h1> {title} </h1>
      <Row>
        <Col>
          <embed src= {`https://drive.google.com/file/d/${src}/preview`} style={{minHeight:"100vh",width:"100%",border:0}}/>
        </Col>
      </Row>
    </Container>
  )
}
export default Pdf