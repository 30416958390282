import "./Prototype_Page.css"

import Slider from "../../../slider/Slider"
import img_s1 from "../Content/80/52_برنامج رسل السلام + إفتتاح حفل التدشين في إستاد جامعة الملك عبدالله للعلوم والتقنية + الأغنية/غلاف أغنية رسل السلام.jpg"
import img_s2 from "../Content/80/52_برنامج رسل السلام + إفتتاح حفل التدشين في إستاد جامعة الملك عبدالله للعلوم والتقنية + الأغنية/غلاف أغنية رسل السلام 2.jpg"

import Player from "../../../video/VideoPlayer"

function Page80() {
  const images1 = [img_s1,img_s2]
      
  return (
    <div className="container py-4 page">
        <h1 className="text-center">52_برنامج رسل السلام + إفتتاح حفل التدشين في إستاد جامعة الملك عبدالله للعلوم والتقنية + الأغنية</h1>
        <hr />
        <h1>حفل أفتتاح برنامج رسل السلام</h1>
        <Player src={'927931562?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write'}/>
        <h1 className="text-center">غلاف أغنية رسل السلام</h1>
        <Slider height={''}  images={images1}/>
    </div>
  )
}

export default Page80