import React from 'react'
import VideoPlayer2 from '../video2/VideoPlayer2'

function Kod31() {
  return (
    <div>
        <p> 
        وقد شُرِفتْ "مجموعة الأغر" بقبولِ الملكِ عبدالله ـ يرحمه الله ـ لمقترحِ إقامةِ  معرضٍ مُصاحبٍ في هيئةِ الأممِ المتحدةِ، يعرضُ دورَ الحضارةِ الإسلاميةِ بتبنيهاِ ودعمِها لمخترعاتٍ ساهمَ فيها أتباعُ الدياناتِ المختلفةِ لإثراءِ المسيرةِ الإنسانيةِ في معرضِ (١٠٠١) "ألف اختراع واختراع" ليتوافقَ معَ إلقاءِ الملكِ عبدالله كلمتَه مقدماً مقترحَه لإقامةِ مركزِ الملكِ عبدالله العالمي للحوارِ بينَ أتباعِ الأديانِ والثقافاتِ.
        </p>
        <VideoPlayer2 src={'https://app.videas.fr/embed/media/a0ae2cfd-4d89-46f1-8fcb-ca4f91db551b/'} />
    </div>
  )
}

export default Kod31