import React from 'react'
import { Container } from 'react-bootstrap'
import Slider from '../../components/slider/Slider'
// import VideoPlayer from '../../components/video/VideoPlayer'
import VideoPlayer from '../../components/qr-components/rest of pages/Main_Comp/VideoPlayer'
import bassmalaMedia from './bassmalaMedia'

function Bassmala() {
  return (
    <Container fluid className="container-page pb-sm-4" >
            <Container className='py-lg-4 home-title  py-5'>
                <h1 className='title text-dark'>البسملة</h1>
                <p className='text-dark  py-4'>البسملة كما أبدعها الخطاط العالمي أحمد مصطفى، على شكل تاج يحمل بين طياته (بسم الله الرحمن الرحيم عليه توكلنا وبه نستعين ).
"الخط الكوني" من أهم الأعمال التي تؤرخ وتبحث في عظمة الخط العربي، فما قام به الدكتور أحمد مصطفى يعدّ من أهم الدراسات على مرّ التاريخ. وقد كان لنا في "ليان الثقافية" مساهمة في هذا العمل العظيم، عسى أن يوفق المولى وتعم به الفائدة.
الدكتور أحمد مصطفي صديق ومحبّ، كانت مساهماته في أعمال كثيرة ذات أثر إيجابي على إثراء رسالتنا الثقافية والفنية. ومن الأعمال التي نعتز بها "لوحة الإسراء والمعراج" التي كانت عنوانا لمعرض (مساجد تشدّ إليها الرحال) الذي أقيم بمناسبة اختيار مكة المكرمة أول عاصمة للثقافة الإسلامية.</p>
              <Slider images={bassmalaMedia.slide} />
              <h3 className='title text-dark my-5 text-decoration-none'>فديو مساجد تشجد اليها الرحال -دكتور احمد مصطفى</h3>
              <VideoPlayer src={'928514683?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write'}/>
            </Container>
        </Container>
  )
}

export default Bassmala
