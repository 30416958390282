import "./Prototype_Page.css"


import Pdf from "../../../pdf/Pdf"

function Page71() {
      
  return (
    <div className="container py-4 page">
        <h1 className="text-center">48_كلمة منتدى التنافسية</h1>
        <hr />
        <Pdf src={'1hhkA1s7RujbcCsN06yBdDcyNcrXKn15q'} title={'كلمة منتدى التنافسية'}  />
                
    </div>
  )
}

export default Page71