import React from 'react'
import { Container, Image } from 'react-bootstrap'
import p1 from './صورة إجتماع الجبيل.jpg'
import VideoPlayer from '../../video/VideoPlayer'
import Pdf from '../../pdf/Pdf'
function Q32() {
  return (
    <Container className='text-center my-3' style={{minHeight:'40vh'}}>
        <h1 className='mt-5 mb-3'>الرؤيا الاسلامية للتنمية في ظل مقاصد الشريعة</h1>
        <Pdf src={'1euiz9PX_F5XJQuvGwLq9LtTIgWMfqQwi'}  />
        <Container>
            <h1 className='mt-5 mb-3'>صور اجتماع الجيليين</h1>
            <Image style={{width:"100%"}} src={p1} />
        </Container>
        <h1 className='mt-5 mb-3'>فديو الاجتماع</h1>
        <VideoPlayer src={'928541933?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write'} />
        <Pdf src={'12tk_dY-_-ykapLJkSzdPhmmog0C4hdb3'} title={'المجتمع المعرفي'} />
        <Pdf src={'1N9WTGaiWTx7DxdgFsWZ-IWmllPvEWnlN'} title={'الملخص التنفيذي لإستراتجية المجتمع المعرفي'} />
        <h2>خطبة عرفة كاملة | لفضيلة الشيخ د. ماهر المعيقلي من مسجد نمرة بعرفة | السبت ٩ ذو الحجة ١٤٤٥هـ</h2>
        <div className=" mb-5 d-flex justify-content-center video-responsive">
          <iframe
            src={`https://www.youtube.com/embed/zNeXR84uWOE?si=fRPQa26ujZxFyQLV`}
            width="560"
            height="315"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            title="Vimeo video player"
          ></iframe>
      </div>
      <Pdf src={'1MI0UUtKwHy0rNPSQ_QiFYz6N8-k8sqJ_'} title={'Sustainable Socioeconomic Development in the New Economy: Challenges and Opportunities'} />
    </Container>
  )
}

export default Q32