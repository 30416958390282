import "./Prototype_Page.css"
import Pdf from "../../../pdf/Pdf"

function Page69() {
      
  return (
    <div className="container py-4 page">
        <hr />
        <Pdf src={'1ZrxrYy0rue2KTFS3QHxUpvG4HG40_RQd'}  />
        
    </div>
  )
}

export default Page69