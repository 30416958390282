import React from 'react'
import Slider from'../slider/Slider'
import p2 from '../../media/Content/Media/2.1.jpg'
import p3 from '../../media/Content/Media/2.2.jpg'

function Kod3() {
  return (
    <div>
      <p>
        أتذكرُ وأنا طفلٌ أنّ أحدَ الحواراتِ كانتْ عنْ آلة التصويرِ (الكاميرا) التي أحضرَها وأدهشتْني في حينِها، وذلك عندما شرحَ لنا الملكُ عبدالله ـ يرحمه الله ـ بإعجابٍ عن تطورِ التقنيةِ في كاميرا (البولورويد) وكيفَ تقومُ بالإظهارِ الفوريّ للصورِ! وهو ما انعكسَ أثرهُ علي إلى اليومَ مُتجسدًا في هوايتي للتصويرِ. 
      </p>
      <Slider height={''} images={[p3,p2]}/>
    </div>
  )
}

export default Kod3