import "./Prototype_Page.css"
import Pdf from "../../../pdf/Pdf"


function Page89() {
        
  return (
    <div className="container py-4 page">
        <Pdf src={'1YvNMIeYC0cqxQCxQN1YsZZdMod_jVY2I'} title={'«التحول الوطني» في السعودية .. رؤية ورسالة'} />
        <hr />              
    </div>
  )
}

export default Page89