import "./Prototype_Page.css"
import { Image } from "react-bootstrap"


import Player from "../Main_Comp/VideoPlayer"
import img1 from "../Content/87/60_مقال عكاظ يوم الوطن يوم عبدالعزيز/مقال عكاظ - يوم الوطن يوم عبدالعزيز.jpg"
import Pdf from "../../../pdf/Pdf"


function Page87() {
        
  return (
    <div className="container py-4 page">
        <Pdf src={'1-YQDupFyeQNXtZiSO0B3GAm3rGm1jek2'}  />
        <hr />
        <h1>مركز نور للمعرفة</h1>
        <Player src={'927930302?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write'}/>
        <hr />
        <h1 className="text-center">60_مقال عكاظ يوم الوطن يوم عبدالعزيز</h1>
        <br />
        <Image src={img1} className="w-100"/>       
    </div>
  )
}

export default Page87