import "./Prototype_Page.css"
import Player from "../Main_Comp/VideoPlayer"
import Slider from "../../../slider/Slider"

import img_s1 from "../Content/64/41_نشيد إروها سيدي/إروها سيدي.jpg"
import img_s2 from "../Content/64/41_نشيد إروها سيدي/إروها سيدي 2.jpg"
import img_s3 from "../Content/64/41_نشيد إروها سيدي/إروها سيدي 3.JPG"
function Page64() {
    const images1 = [img_s1,img_s2,img_s3]
  
  return (
    <div className="py-4 page">
      <h1 className="text-center">41_نشيد إروها سيدي</h1>
      <hr />
      <Player src={'927933668?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write'}/>
      <hr />
      <Slider height={''}  images={images1}/>
    </div>
  )
}

export default Page64