import "./Prototype_Page.css"

import Player from "../Main_Comp/VideoPlayer"
import Slider from "../Main_Comp/Slider"
// import vid1 from "../Content/81/53_مشروع وقف الكشافة/فيديو مقترح مركز الكشافة السعودي.mp4"
import img_s1 from "../Content/81/54_صورة المرشدات بعرفات/01.JPG"
import img_s2 from "../Content/81/54_صورة المرشدات بعرفات/02.JPG"
import Pdf from "../../../pdf/Pdf"


function Page80() {
  const images1 = [img_s1,img_s2]
      
  return (
    <div className="container py-4 page">
        <h1 className="text-center">53_مشروع وقف الكشافة</h1>
        <hr />
        <Pdf src={'1dq6peOrJEOwJbJzr5zq1GkglIbwj1P7O'} title={'مقترح مركز الكشافة السعودي'} />
        <hr />
        <h1>فيديو مقترح مركز الكشافة السعودي</h1>
        <Player src={'927930800?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write'}/>
        <hr />
        <h1 className="text-center">54_صورة المرشدات بعرفات</h1>
        <Slider src={images1}/>
        
    </div>
  )
}

export default Page80