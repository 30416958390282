import React from 'react'

function Kod2() {

  return (
    <p className="col-md-12 text-center ">
            "ملك الحوار" نعم، إنّه ملكٌ الحوار، 
            ليس قولا، لكنْ مسيرةَ عُمر وأسلوبَ حياةٍ تشهدُ لهُ بذلكَ. أرجو أنْ ترتقيَ بساطةُ طرحي هذه الليلةِ إلى سموّ وعظمةِ
             ومكانةِ وتلقائية ِعبدالله بن عبدالعزيز" الإنسان " هذه الصفةُ المُستمدّةُ منْ قوة إيمانهِ وصدقِ نواياه. 
            أذكرُ عندما سُئِلت عن عبدالله بن عبدالعزيز وصفتِهِ، أجبتُ بأنّه: "الخطُّ المستقيمُ الذي يربطُ بينَ نقطتين". 
              <br />
              <br />
            تواصلِي معَ الحضورِ الكريمِ هذهِ الليلةِ المباركةِ - بإذن المولى- وتوفيقهِ مدعاتُهُ قَدَرٌ سيرَّهُ الربُّ - عزّ وعلا- لي حينَ كرمني في مسيرةِ حياتي أنْ أعيشَ مراحلَ منْ عمري، أتشرفُ بِتشربّ تجاربَ في كَنَفِ " ملك الحوار"، الذي سخرَّ ما وهبَهُ الربُّ منْ قدراتٍ، وجاهٍ، وسُلطةٍ لخدمةِ الإنسانيةِ.
              <br  />
              <br />
            أبدأُ بتكوينِ شخصيةِ الإنسانِ، فما سَمعْتُه منَ المغفورِ له- بإذن الله ـ خادمِ الحرمين الشريفين الملك عبدالله بن عبدالعزيز منْ أحاديثَ عنْ تجاربَ مرّت به في حياتِهِ، وعنْ مواقفَ آمنَ بها وتمسّكَ بثوابتِها، على مستوى علاقتهِ مع والدهِ المؤسسِ ـ تغمدَّه المولى بواسع رحمته ـ ومع عائلتهِ وأصدقائهِ، وكذلك معنا نحنُ في صغرِنا، تضافرتْ جميعُها ليكونَ (الحوارُ)أهمَّ سماتِها وجزءًا منها. 
        </p>
  )
}

export default Kod2