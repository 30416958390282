import React from 'react'
import VideoPlayer2 from '../video2/VideoPlayer2'

function Kod34() {
  return (
    <div>
        <p> 
        مركزُ الملكِ عبدالله العالمي للحوارِ بينَ أتباعِ الأديانِ والثقافاتِ (كايسيد)، هي منظمةٌ دوليةٌ تأسستْ عام ٢٠١٢م بمبادرةٍ منَ المملكةِ العربيةِ السعوديةِ وجمهوريةِ النمسا ومملكةِ إسبانيا إلى جانبِ الڤاتيكان بصفتهِ عضوًا مؤسسًا مراقبًا، في مدينةِ ڤيينا. 
            <br />
        <br />
        يسعى المركزُ لدفعِ مسيرةِ الحوارِ والتفاهمِ بين أتباعِ الأديانِ والثقافاتِ المتعددةِ، والعملِ على تعزيزِ ثقافةِ احترامِ التنوعِ، وإرساءِ قواعدِ العدلِ والسلامِ بينَ الأممِ والشعوبِ. ويرى المركزُ أنَّ الدينَ، قوةٌ فاعلةٌ لتعزيزِ ثقافةِ الحوارِ والتعاونِ لتحقيقِ الخيرِ للبشريةِ، حيث يعملُ على معالجةِ التحدياتِ المعاصرةِ التي تواجهُ المجتمعاتِ، بما في ذلكَ التصدي لتبريرِ الاضطهادِ والعنفِ والصراعِ باسمِ الدينِ وتعزيزِ ثقافةِ الحوارِ والعيشِ معًا.
               <br />
        <br />
        يتألفُ مجلسِ إدارةِ المركزِ منْ قياداتٍ دينيةٍ، منَ المسلمين والمسيحيين واليهودِ والبوذيين والهندوسِ. ويُعدُّ المركزُ أولَ مُنظمةٍ دوليَّةٍ تعملُ على تفعيلِ دورِ الأفرادِ والقياداتِ والمؤسساتِ الدينيةِ لمساعدةِ صانعي السياساتِ في بناءِ السلامِ والتعايشِ السلميّ تحت مظلةِ المواطنةِ المشتركةِ، سدًا للفجوةِ بينَ القياداتِ الدينيةِ وصانعي السياساتِ خاصةً في المنظماتِ الدوليّة، وإيجادِ حلولٍ ناجعةٍ، ومُستدامةٍ وتحقيقِ نتائجَ إيجابيةٍ. 
        </p>
        <VideoPlayer2 src={'https://app.videas.fr/embed/media/e3d62331-b59e-4230-a573-da526f9fe820/'} />
    </div>
  )
}

export default Kod34