import React from 'react'
import p5 from '../../media/Content/Media/4.jpg'
import p6 from '../../media/Content/Media/5.jpeg'
import Slider from '../slider/Slider'

function Kod5() {
  return (
    <div>
        <p>
        الحوارُ والشفافيةُ كانا سمةً وبصمةً في تعاملِهِ طوالَ حياتهِ. ومنَ القصصِ
                 التي شدّتني ما ذكرهُ من أنَّه في يومٍ منَ الأيامِ عُرضتْ أمامه مجموعةٌ منَ الخيولِ العربيةِ الأصيلةِ جُلبتْ منَ العراق. وقد 
                شدّت روحَه فرسٌ، ولكنَّهُ لم يكنْ يملكُ المالَ الكافيَ لشرائِها، فذهبَ إلى منزلِهِ مكسورَ الخاطرِ، ودارَ حوارٌ بينَهُ وزوجتِه،
                 أحسَّتْ من خلاله بمشاعرِهِ الحزينةِ، فأشارتْ عليهِ بوسيلةٍ تؤمنُ له المبلغَ الذي يحتاجُهُ لشراءِ الفرسِ. كانت هذه
                 الفرسُ أولَ الخيلِ التي اقتناها، فأسكنَها معه في منزلِهِ، وكان يحاورُها ويشاركُها طعامَ إفطارِهِ كلَّ يومٍ.
                 ويوم السباقِ عُرضتِ الخيلُ أمام الملك عبدالعزيز- رحمه الله- ولم ينظرْ لفرسهِ أحدٌ سوى عمه سعود بن عبدالرحمن،
                 وكان خبيراً في الخيلِ، ودارَ بينهما حوارٌ قال له فيه: "يا عبد الله، إن كان هناك سابقٌ اليومَ، فهو أنت وفرسُك".
        </p>
        <Slider height={''} images={[p5,p6]} />
    </div>
  )
}

export default Kod5