import React from 'react'
import './qr.css'
import { useLocation, useSearchParams } from 'react-router-dom'
import qrData from '../../qrData';
import { Container } from 'react-bootstrap';
import Ref from '../ref/Ref';
function Qr() {
    // const [searchParams] = useSearchParams();
    // const page_no = parseInt(searchParams.get('page_no')) 
    const location = useLocation()
    const url = `${location.pathname}${location.search}`
    let filterd = qrData.filter(e=> {return e.url === url})
    // console.log(url,filterd)
    const renders = ()=>{
      if (!filterd.length  ) {
      return (<Ref/>)
    }
      else{
        
        return(<Container fluid className='mt-5' >
              <h1 className='text-dark mb-5 text-center'> {filterd[0].title} </h1>
              {filterd[0].content}
            </Container> 
        )
            }
    }
    
  return (
    renders()
  )
}

export default Qr