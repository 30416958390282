import React from 'react'

function Kod6() {
  return (
    <p>
        عبدالله بن عبدالعزيز كان سبَّاقًا وسابِقًا لوقتِه. قد يكون أحدُ الأسبابِ في ذلك 
            - منْ وجهةِ نظري- ما مرّ به من تجاربَ إيجابيةٍ وسلبيةٍ، فعندما كان يافعًا وقف أمام الملكِ المؤسسِ 
            عبدالعزيز ـ تغمدَّه الله بواسع رحمته ـ ليحاورَهُ ويتحملَ عقوبةَ السجن في أقسى موقعٍ بما يُسمي 
            (الدباب) داخلَ المصمك مٌلتزمًا بموقفهِ ومُدافِعا بشجاعةٍ عنْ وجهةِ نظرِهِ بكلّ احترامٍ أمامَ والدِهِ. 
              <br />
              <br />
            عبدالله بن عبدالعزيز كان الحوارُ عنده نابعًا من أحاسيسَ داخليةٍ كونتْها خبرةُ الأيامِ، وترسخ في قناعتهِ بأنَّه أفضلُ وسيلةٍ لحلّ 
            النزاعاتِ والتعايشِ الإنسانيّ. ونرى تأثيرَ ذلك واضحًا خلالَ مسيرةِ حياتِهِ وقدرِهِ الذي حملَهُ منذُ 
            صغرهِ. فالسفرُ والتعرفُ على الحضاراتِ والشعوبِ المختلفةِ ربما كانَ هو العاملَ الأهمَ في تعزيز 
            إيمانِهِ بأنّ الانفتاحَ والحوارَ هما النّهجُ الأقدرُ للتلاقي بين نقطتين. كان مُؤمنًا بأنّ الحوارَ 
            لا يهدفُ للإقناعِ، وإنما لتعريفِ الآخرِ بوجهةِ نظرهِ، واحترام ِالاختلافِ في الرأي والأفكارِ وتبادلِها.
    </p>
  )
}

export default Kod6