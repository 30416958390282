import React from 'react'
import VideoPlayer2 from '../video2/VideoPlayer2'
import {Image} from 'react-bootstrap'

// import p18 from '../../media/Content/Media/8.jpg'
import p19 from '../../media/Content/Media/إروها سيدي.jpg'

function Kod13() {
  return (
    <div>
        <p > 
        جامعةُ الملكِ عبدالله للعلوم والتقنية " كاوست " هي التجسيدُ الحيُّ لحوارِ العقولِ البنَّاء المُثمرِ والمفيدِ على أعلى المستوياتِ، فهي صرحٌ  قائمٌ ممتدٌّ لحوارِ العقولِ بينَ جميعِ دولِ العالمِ للاستثمارِ المُستدَام في أهمّ مُقوماتِ الحياةِ الإنسانيةِ : الماءِ، والغذاءِ، والطاقةِ، والبيئةِ؛ لإيجادِ الحلولِ لها وتأمينِ  مُستقبلِ الإنسانيةِ. بما يحققُ لها الرخاءَ في حياتِها.
        </p>
        <VideoPlayer2 src={'https://app.videas.fr/embed/media/8c32e463-0ac2-4bcf-a290-de5ae2ff4412/'} />
        <br/>
        <Image width={'100%'} height={''} src={p19} />
    </div>
  )
}

export default Kod13