import React from 'react'
import { Container } from 'react-bootstrap'
import Pdf from '../../components/pdf/Pdf'

function Book({title,src}) {
  return (
    <Container className='py-5' style={{minHeight:'40vh'}}>
        <Pdf title={title} src={src}/>
    </Container>
  )
}

export default Book