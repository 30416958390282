import "./Prototype_Page.css"

import Player from "../Main_Comp/VideoPlayer"

import Pdf from "../../../pdf/Pdf"


function Page70() {
      
  return (
    <div className="container py-4 page">
        <h1 className="text-center">47_تطوير مدرسة طيبة</h1>
        <hr />
        <Pdf src={'1nQYRtX6aemusbII93yA7nLeP1CCGD7y7'} title={'دار القلم'} /> <br />
        <Pdf src={'1VX6g3h_NXFhUt1eQqXS1ZgwQAYqWDtuv'} title={'عرض مركز اقرأ'} />
        <hr />
        <h1 className="text-center" >دار القلم</h1>
        <Player src={'927929621?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write'}/>
        
    </div>
  )
}

export default Page70