import React from 'react'

import Pdf from '../../pdf/Pdf'
import { Container } from 'react-bootstrap'

function Q30() {
  return (
    <Container  style={{minHeight:'40vh'}}>
        <Pdf src={'1Dr5VV68N5B7bp-INXK7MKHg4QzKw-V6z'} title={'ﻣﺒﺎﺩﺭﺍﺕ استراتيجية لتحسين اداء الاقتصاد 2006 pdf'} />
        <br/>
        <br/>
        <Pdf src={'1hC0pNRuzRfiH0if0KHgRk_wM3p81FzCJ'} title={'مقترح إستراتيجي لتنمية الاقتصاد السعودي_2001م pdf'} />
    </Container>
  )
}

export default Q30