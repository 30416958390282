import "./Prototype_Page.css"
import { Image } from "react-bootstrap"
import img1 from "../Content/66/43_ملصق جامعة الملك عبدالله/ملصق جامعة الملك عبدالله.jpg"

function Page66() {
      
  return (
    <div className="container py-4 page">
        <h1 className="text-center">43_ملصق جامعة الملك عبدالله</h1>
        <hr />
        <Image src={img1} className="w-100"/>
        
    </div>
  )
}

export default Page66