import React from 'react';

const VideoPlayer = ({src}) => {
    return (
        
        
        <div className="d-flex justify-content-center video-responsive">
        <iframe
          src={`https://player.vimeo.com/video/${src}`}
          width="560"
          height="315"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          title="Vimeo video player"
        ></iframe>
      </div>
            
    );
}

export default VideoPlayer;
