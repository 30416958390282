import React from 'react'
import VideoPlayer2 from '../video2/VideoPlayer2'

function Kod24() {
  return (
    <div>
        <p>
        لا بدّ منْ ذكر معنى الحوار الحقيقي الذي يلمسُ الروحَ بين القائدِ ورعيتِه، وهنا لا أعتقدُ أن أحدًا ينسي تلك الصورَ المؤثرةَ وهو يحاورُ مواطنيهِ مُتفقدًا أحوالَهم مُتلمسًا حاجاتِهم بزيارتِه لبيوتِهم في أفقرِ حاراتِ عاصمتِه، ليرى بنفسهِ ويواجهُ الواقعَ الذي حاولَ أنْ يحسنَه. 
            <br />
            <br />
            كذلك لا أحدَ ينسى كرسي عبدالله بن عبدالعزيز الإنسانِ، الذي أجلس عليه مواطنيه مُقابلاً لكرسيه في ديوانه ليسمعَ منهم احتياجاتِهم أو مشاكلِهم ويحاورَهم، ولا أحدَ ينسي رفقـَه ومحبتَه واحترامَه حين قدمَ كأسَ ماءٍ لأحدِ مراجعيه بعد انفعالِه ليهدئَهُ، ويُكمِلَ حوارَه معه. وكم منَ القصص سمعناها منذ بدايةِ عملهِ بالدولةِ وبحكم مسؤولياته في الحرس الوطني ونقاشاتِهِ وحواراتِهِ مع أفرادِ باديةِ الجزيرةِ العربيةِ ، وكم منَ الحواراتِ مع تلك القبائلِ أنقذَ بها الكثير منَ الأرواحِ، ونزع فتيلَ حروبٍ قبليةٍ مع رؤساءِ وشيوخ وأعيانِ دولٍ مجاورةٍ 
        </p>
        <VideoPlayer2 src={'https://app.videas.fr/embed/media/a6d9612b-fe71-4378-851d-e9acc8bec980/'} />
    </div>
  )
}

export default Kod24