import React from 'react'
import Pdf from '../pdf/Pdf'
import {Image} from 'react-bootstrap'
import p23 from '../../media/Content/Media/19.jpeg'
function Kod19() {
  return (
    <div>
        <p>
        تطورتِ الفكرةُ وقدمَ الدراسةَ الأميرُ عبدالمجيد بن عبدالعزيز أميرُمنطقة مكةَ المكرمة آنذاك للملك عبدالله بن عبدالعزيز ـ يرحمهما الله ـ كمشروع مدينةٍ متكاملةٍ بمناسبةِ اختيار مكة َعاصمةً للثقافةِ الإسلاميةِ، لِتسهمَ أولًا، في جمعِ الكلمةِ والتعريفِ بالحضارةِ والثقافةِ الإسلاميةِ للمسلمِ، وللتعرفِ ثانيًا على عظمةِ رسالة الإسلام التي وحدتْ هذه الأمةَ بالرغم منِ اختلاف أعراقِها وحضاراتِها ولغاتِها، وكذلك لغيرِ المسلمِ ليتعرفَ ويلمسَ ويحاورَ، خاصةً وأنَّ في هذا الموقعِ كان "صلحُ الحديبيةِ" أعظمَ حوارٍ دارَ في تاريخِنا الإسلامي.
        </p>
        <Image style={{width:'100%'}} src={p23} />
        <Pdf title={'الملخص التنفيذي لمشروع الشميسي'} src={'1nb_S7-y_HG6vfr5xhONPuFiIZt8FdcR4'}  />
    </div>
  )
}

export default Kod19