import React from 'react'
import { Container } from 'react-bootstrap'

function About() {
  return (
    <Container fluid className='container-page'>
      <Container className='pg-dark py-lg-4 home-title py-5'>
        <h1 className='title text-dark'>عن المؤلف</h1>
        <p className='mt-5 '>
        * حاصل على البكالوريوس في إدارة الاعمال من كلية منلو، منلو پارك كاليفورنيا
  و برنامج الماجستير والدكتوراة في الهندسة الصناعية بجامعة ستانفورد پالو ألتو كاليفورنيا.
  <br/>
  <br/>
* شارك في مركز الأبحاث والتنمية الصناعية التابع لوزارة التجارة والصناعة بين عامي ١٩٧١-١٩٧٣ م كمتدرب.
<br/>
<br/>
* عين وكيل للحرس الوطني في القطاع الغربي عام ١٩٩٢ م
<br/>
<br/>
* في عام ٢٠٠٤ م عين مساعد لرئيس الأستخبارات العامة.
<br/>
<br/>
* من عام ٢٠٠٩ م إلى عام ٢٠١٣ م عين وزير للتربية والتعليم
<br/>
<br/>
* ترأس البرنامج الوطني للطفولة.
<br/>
<br/>
* نائب لرئيس مؤسسة الملك عبدالعزيز ورجاله للموهبة وابداع (موهبة)
<br/>
<br/>
* رئيس للكشافة السعودية (أسس برنامج رسل السلام عام٢٠١١ م ) .
<br/>
<br/>
* ترأس وفد المملكة العربية السعودية لدي منظمة الامم المتحدة للتربية والعلوم والثقافة( اليونيسكو).
<br/>
<br/>
* مؤسس ورئيس الاتحاد السعودي للفروسية ١٩٩٠ ـ٢٠٠٠ م .
<br/>
<br/>
* رئيس مجلس أمناء صندوق الفروسية.
<br/>
<br/>
* مؤسس وعضو مجموعة الأغر للفكر استراتيجي .
        </p>
      </Container>
    </Container>
  )
}

export default About
