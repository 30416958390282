import React from 'react'
import {Image} from 'react-bootstrap'
import p7 from '../../media/Content/Media/6.1.jpg'
function Kod7() {
  return (
    <div>

        <p>
            
        لبنانُ منْ أهم المحطاتِ التي دفعتْ  إلى تبنيهِ ـ رحمه الله ـ لغةَ الحوارِ وتشجيعهِ على طرحِها بينَ الفرقاءِ بمُختلفِ دياناتِهم وطوائِفهم وخلفياتِهم الإثنيةِ والسياسيةِ والفكريةِ. أتذكرُ عندما كنا نقضي الصيفَ في لبنانَ، كيف  سنحتْ لي فرصُ زياراتِ مجلسهِ ـ يرحمه الله ـ في فندقٍ على مدخلِ مصيفِ "عاليه"، حيث مجلسهُ المفتوحُ للجميعِ يرتادهُ المسلمُ السنيّ والشيعيّ والدرزيّ والأرمنيّ والمسيحيّ الكاثوليكيّ والأرثوذكس والمارونيّ واليهوديّ واللادينيّ جنبًا إلى جنبٍ في حضرة أميرٍ فتحَ بصدقٍ قلبَهُ قبلَ بابِهِ وعلى مسافةٍ واحدةِ منَ الجميعِ، مُرحبًا بالإنسانِ في المقامِ الأوّلِ، ومٌكرمًا له، مُجسدًا إيمانَه بما نشأ عليه من قولِ الحقّ:
        <br />

            <span style={{color: "#000 !important;"}} >" يا أيها الذين آمنو إنا خلقناكم من ذكر وأنثي وجعلناكم شعوباً وقبائل لتعارفوا إن أكرمكم عند الله أتقاكم إن الله عليم خبير ". صدق الله العظيم.</span>
        </p>

        <Image style={{width:'100%'}} src= {p7} />

    </div>
  )
}

export default Kod7