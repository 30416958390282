import "./Prototype_Page.css"
import Pdf from "../../../pdf/Pdf"

function Page67() {
      
  return (
    <div className="container py-4 page">
        <h1 className="text-center">44_ كلمة (التعليم للمواطنة) بمركز الملك فيصل</h1>
        <hr />
        <Pdf src={'128Ai7f39-xulMPaZKGttnqXpspIUfYye'} title={'التعليم للمواطنة'} />
        
    </div>
  )
}

export default Page67