import React from 'react'
import { Container, Image } from 'react-bootstrap'
import p from './شعار مجموعة الأغر.png'
import Pdf from '../../pdf/Pdf'
import VideoPlayer from '../../video/VideoPlayer'
function Q28() {
  return (
    <Container className='my-5'>
        <Container className='d-flex justify-content-center'  fluid>
            <Image src={p} />
        </Container>
        <h1 className='mt-5 mb-3'>مجموعة الاغر</h1>
        <Pdf src={'1IhCHuepwk_aQGfgCIshXLWczikzNHM5X'} title={'عرض مجموعة الأغر pdf'} />
        <h1 className='mt-5 mb-3'>فديو عرض مجموعة الاغر</h1>
        <VideoPlayer src={'927935345?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write'} />
    </Container>
  )
}

export default Q28