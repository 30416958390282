import React from 'react'
import p33 from '../../media/Content/Media/29.2.jpg'
import p34 from '../../media/Content/Media/29.1.jpg'
import p35 from '../../media/Content/Media/29.3.jpg'
import Slider from '../slider/Slider'
function Kod29() {
  return (
    <div>
        <p>   
        رحمك اللهُ عبدالله بن عبدالعزيز !لقد شرفتَني وكرمتَني وعلمتَني، ولا أنسى تلك الليلةِ الرمضانيةِ المباركةِ، قبلَ الإفطارِ وأنت تنظرُ إلى بيتِ اللهِ العتيقِ وتتحدثُ وسموُّ الأميرِ خالد الفيصل أمير منطقة مكة المكرمة بجانبكَ ،وكذا أصحابُ السموّ، وبوجودِ الأخِ فيصل بن معمر الأمينِ العامِ لمركزِ الملكِ عبدِ الله العالمي للحوارِ بينَ أتباعِ الأديانِ الذي أسسْتَهُ مؤمنًا برسالتِهِ الإنسانيةِ العالميةِ عندما أشرْتَ إلي قائلًا: "هذا هو أولُ شخصٍ أخبرتُهُ عنْ مركزِ الحوارِ". شرفٌ كبيرٌ يا " ملكَ الحوارِ " ـ يرحمك الله ـ  لقد كرمْتَني وشرفْتَني مرة أخرى كما هي عادتُك بقبولِكَ لوحةَ " ملك الحوار " التي لونَّها بمشاعرِهِ الفنانُ ضياء عزيز ضياء، وقدمْتُها محملةً بإيماني برسالتِك ودعوتِك الصادقةِ إلى الإنسانيةِ. 
        </p>
        <p>
        قدرْتَ إيمانِي برسالتِك ، يرحمُك اللهُ، وتقبلْتَها 
        وأمرْتَ بعرضِها أمامَ قادةِ العالمِ الإسلاميّ بمكةَ المكرمةِ في عام ٢٠١٢م بمناسبةِ
            انعقادِ الدورةِ الرابعةِ لمؤتمرِ القمةِ الإسلاميّ الاستثنائيّ (تعزيز التضامن الإسلامي)، ثم تمّ نقلُ
            اللوحةِ بعدَ ذلك إلى مركزِ الملكِ عبدالله العالمي للحوارِ بينَ أتباعِ الأديانِ بمدينةِ ڤينا
            بالنمسا.
        </p>
        <Slider height={''} images={[p33,p34,p35]} />
    </div>
  )
}

export default Kod29