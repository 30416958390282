import React from 'react'
import { Container } from 'react-bootstrap'
import VideoPlayer from '../../video/VideoPlayer'
import Audio from '../../audio/Audio'
import a1 from './02_أغنية التوحيد/a1.mp3'
import a2 from './02_أغنية التوحيد/a2.mp3'
import a3 from './02_أغنية التوحيد/a3.mp3'
import a4 from './02_أغنية التوحيد/a4.mp3'
import a5 from './02_أغنية التوحيد/a5.mp3'
import a6 from './02_أغنية التوحيد/a6.mp3'
import a7 from './02_أغنية التوحيد/a7.mp3'
import Pdf from '../../pdf/Pdf'
function Q23() {
  return (
    <Container>
        <Pdf src={`1z_y9nnOrpCPdvklhBHkpQTRzmgA2MtA6`} name={' مختصر كتاب التوحيد'} />
        <h1 className='underline mt-5 mb-3'>فديو معرض كتاب التوحيد</h1>
        <VideoPlayer className='my-1' src={'928532240?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write'} />
        <h1 className='underline mt-5 mb-3'>الاغاني</h1>
        <Container fluid >
            <Audio title={'1-عبدالعزيز يا منية الابطال'} src={a1}/>
            <Audio title={'2- أنا نجد'} src={a2} />
            <Audio title={'3- أنا الحسا'} src={a3} />
            <Audio title={'4- أنا الجنوب'} src={a4} />
            <Audio title={'5-أنا الحجاز'} src={a5} />
            <Audio title={'6- أنا الشمال'} src={a6} />
            <Audio title={'7-غاب الأسد'} src={a7} />
        </Container>
    </Container>
  )
}

export default Q23