import React from 'react'

function Kod23() {
  return (
    <p>
        منَ المواقفِ التي لا أنساها ذلك اليوم في مؤتمرِ مكةَ في عام ٢٠١٢م ورؤساءُ الدولِ الإسلاميةِ متوجهين إلى وليمةِ العشاءِ بقصرِ الضيافةِ بمشعرِ منى، وهو ـ يرحمه الله ـ يتقدمُ ضيوفَه مُمسكًا يدّ أحمدي نجاد الرئيس الإيراني، مُحاورا ومُتقبلا للآخر. سألته لاحقًا :" سيدي لماذا احتفيت بأحمدي نجاد؟" نظر إلي الملك وقال: " مُجبرٌ أخاك لا بطل، نحاول بناء علاقات أخوة واحترام معه ومع من قبله، مثل : رفسنجاني وخاتمي، لجمع كلمة المسلمين وتوحيد صفوفهم كأمة إسلامية مهما كان الاختلاف، في وجه من يريد بها الشرَّ وزرعَ الفرقةِ بينها. فنيتُنا صادقةٌ لوجههِ تعالى، وهذه مسؤوليُتنا وواجبُنا، والله أعلمُ بالنوايا."
    </p>
  )
}

export default Kod23