import React from 'react'

function Kod28() {
  return (
    <p>
        هذا هو عبدالله بن عبدالعزيز الذي آمنَ بالحوارِ وسعى ليجعلَه علامةً فارقةً في حياتهِ وفي حكمهِ وتاريخِ. فاللهُ أعلمُ إذا كان قدري قد كتبَ لي أن أتشرفَ بأنْ أكونَ جزءًا 
في مسيرةِ رسالةِ حوارِ عبد الله بن عبد العزيز، وهنا حدثٌ  شرفني به ـ يرحمه الله ويسكنه فسيح جنانه ـ حين كرمَّني كعادتِه ومنحني الثقةَ، وذلك عندما استدعاني في يومٍ منَ الأيام إلى مقرِّ إقامته في منطقة " بسكورة بالدار البيضاء " في المغرب الشقيق، وأسرّ لي قائلا: " فيصل، سأخبرُك بشيءٍ أفكر فيه وأعتزم تنفيذَه- بتوفيقِ الله وقدرتِه وتمكينه- فقد قررتُ أن أحمل رسالةَ الحوارِ إلى هيئة الأمم المتحدة، وأن نؤسسَ مركزًا عالميًا للحوار" لم أتمالكْ نفسي، وقلت له: " سيدي، إن الحوار رسالتك حملتها طوال حياتك،  ونبعتْ من صدقِ وإيمانٍ برسالةٍ إنسانيةٍ للعالمين، فأنت أقربُ منْ يوصلُها ويمّكن التواصل بها تحت مظلةٍ أمميةٍ وفي إطارِ عالمي يجمعُ ويوحدُ كلمةَ الحقّ والعدلِ والمساواةِ.
               <br />
            <br />
            كانت فرحتي لا توصفُ وعدتُ إلى الفندقِ وأنا أفكرُ بما يحملُهُ هذا القائدُ المسلمُ بكلّ بأمانةٍ وصدقٍ تجاه أخيهِ الإنسانِ برسالةٍ عنوانها (الحوار). لم يتوقفْ تفكيري وكتبتُ مشاعرَ فخرِ واعتزازِ حال عودتي إلى الفندقِ وأرسلتُها إلى السكرتارية بالقصرِ وأستلمَها الأخُ خالدُ العيسى، وبعد ساعاتٍ اتصل بي خالدُ العيسى وسألني: "ماذا كتبتْ؟ لقدْ غضبَ سيدي، وأقترحُ أنْ تأتيَ غدًا لتحدثَّهُ وتتفهَم سببَ غضبِه".  حضرت إلى القصر في اليوم التالي وأتيتُ للسلامِ عليه ـ رحمه الله وأحسن مثواه ـ نظرَ إلي بغضبٍ قائًلا ": فيصل أنا وثقتُ بكَ وأطلعتُك على ما أفكرُ فيه، وأنتَ تكتبُ الخبرَ وتنشرُهُ !"، قلت: " سيدي، لقد كتبت كلماتٍ منَ القلب وددتُ أن أعبرَ بها وأمنتُ عليها لتصلَ يدًا بيدٍ إلى مقامِكم الكريمِ من خلالِ سكرتيركم". قال لي " :ما أسررتُ به لك يجبُ أنْ لا يعرفَ عنه أحدٌ، فهذه ثقةٌ وأمانةٌ بين اثنينِ وأنتَ لم تلتزمْ بها. إياي وإياكَ يجبُ أنْ تحافظَ على السرّ إذا أمُنت عليه، فإذا عرفهُ ثالثٌ لم يعدْ سرًّا".
    </p>
  )
}

export default Kod28