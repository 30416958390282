import React from 'react'
import Slider from '../../slider/Slider'
import { Col, Container, Row } from 'react-bootstrap'
import p1 from './01.png'
import p2 from './02.png'
import p3 from './03.jpg'
import p4 from './04.jpg'
import p5 from './05.jpg'
function Q18() {
  return (
    <Container>
      <Row>
        <Col xs={12} className='my-5' >
          <Slider images={[p1,p2,p3,p4,p5]} />
        </Col>
       </Row>
    </Container>
  )
}

export default Q18