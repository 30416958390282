import React from 'react'
import { Container } from 'react-bootstrap'
import Pdf from '../../pdf/Pdf'
import VideoPlayer from '../../video/VideoPlayer'
function Q22() {
  return (
    <Container className='my-5'>
        <Pdf className="mb-5" title={' السجل pdf'} src={'1bVWx07xR6RrYHoXroZXZnyJ4_oqF2Whh'} />
        <h1 className='mt-5 mb-3 text-center'>فيديو الجلسة الأولى لندوة عقود البناء والتشغيل والإعادة بالشرائع بجدة</h1>
        <VideoPlayer src={'928531368?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write'} />
    </Container>
  )
}

export default Q22