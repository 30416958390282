import React from 'react'
import {Image,Container} from 'react-bootstrap'
import p4 from '../../media/Content/Media/3.1.jpg'
import VideoPlayer2 from '../video2/VideoPlayer2'
function Kod4() {
  return (
    <Container>
        <p>
        وقد تشرفتٌ <b >بإهدائهِ</b> كتابِي شروق و غروب الذي يحتوي على لقطاتٍ حواريةٍ من خلالِ عدستي جمعْتُها وقدمْتُها له، تغمدَّهُ المولى برحمتِهِ.
        </p>
        <VideoPlayer2 src={'https://app.videas.fr/embed/media/bee35649-2a30-4970-914c-721f16f5ba87/'} />
        <Image  style={{marginTop:'20px',width:'100%'}} src={p4} />
    </Container>
  )
}

export default Kod4