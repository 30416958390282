import React from 'react'
import {Image} from 'react-bootstrap'
import p25 from '../../media/Content/Media/17.jpeg'
function Kod22() {
  return (
    <div>
        <p>
        في إحدى زياراتِه الخارجيةِ كانت هناك رسائلُ تنعكسُ فيها روحُ الحوارِ الذي كان سمةً واضحةً في وجودِ وتركيبةِ ولغةِ عبدالله بن عبدالعزيزالعفويةِ . كنا في معيته ـ يرحمه الله ـ خلالَ رحلتِهِ حول العالم في عام ١٩٩٨م، وفي مقابلتِه مع الرئيس زيمين رئيس الصين (العظيمة ( كما كان - يرحمه الله  -  يقولها دائماً وهو يحدثه قائًلا: "يا سموّ الأمير أنتم لديكم أعظمُ قوةً وهي البترول"، وأراد أنْ يسترسلَ،  فرفع الملكُ عبدالله  يدَّه قائًلا ":فخامة الرئيس، معذرةً عنِ المقاطعةِ، لدينا شيئان أهمُ منَ البترول، هما مكةُ المكرمة والمدينةُ المنورة، البترولُ مهمٌ كوسيلةٍ، ولكن ثروتَنا الحقيقيةَ والدائمةَ،هي في تشريفِنا بخدمةِ الحرمين الشريفين، قبلةِ المسلمين ومثوى الرسولِ الأمينِ. فإذا كنتم بليوناً ومائتي مليون نسمة، فنحن بليون وخمسمائة مليون مسلم ".
          <br />
            <br />
            رحمك الله ، يامنْ خدم الحرمين الشريفين، وحملَ أمانةَ توصيلِ رسالةِ الإسلامِ السمحةِ، ولا أنسى في مدينةِ شيانغ أقدمِ  مساجدِ الصينِ الذي أُسس على يدي اثنين من الصحابةِ - رضي الله عنهما - عندما حملَا ووصلَا للصين هذا التنزيلِ العظيمِ الكريمِ سنة خمسين من الهجرة. فقد تواصلتْ مع مسلمي الصين، وأنت تستفسرُ عن أحوالهم وتحاورُهم في ذلك المسجدِ القديمِ قائلاً : " إخواني لقد منّ الله علينا جميعًا بهذا الدينِ الحنيفِ وأوصلُهُ على يدّ أسلافنِا رسالةً تدعو إلى السلام والخير والتسامح، فأنتم من يحملُ هذه الرسالةَ في بلدٍ احتواكم، وها أنتم بحمدهِ وتوفيقهِ تنعمون بوحدتكمِ وتكاتفِكم، فمهما كانت الظروفُ يجبُ أن تحترموا قوانينَ البلدِ الذي تعيشون فيه، وأنْ تسعوا إلى التمسكِ بمقاصدِ ومبادئ رسالةِ دينِنا الحنيفِ الداعي إلى التعايشِ والسلامِ ".
        </p>
        <Image src={p25} width={'100%'} />
    </div>
  )
}

export default Kod22