import React from 'react'
import p24 from '../../media/Content/Media/16.jpg'
import {Image} from 'react-bootstrap'

function Kod21() {
  return (
    <>
        <p>
        لا بدّ لي من ذكرِ حدثٍ تكررَ مرارًا، فقد لاحظتُ والكثيرُ منَ الإخوانِ أنّه بعدَ كلّ وليمةٍ لضيفٍ من ضيوفِ الدولةِ في منزلهِ بمدينةِ جدةَ حول المسبح في الدور الأول، وبعد  انتهائه - يرحمه الله ـ منَ الطعام يتناولُ فنجانا منَ القهوة هو وضيوفه مارًّا بالجلسة الصغيرة التي كان يجلسُ بها كل ليلةٍ بجانب المسبح، ويقفُ مع ضيوفه أمام مُجسمٍ لاتفاقية ترسيمِ الحدود مع اليمن الشقيق ويشرحها لهم. وهنا نرى اهتمامَهُ بدور الحوار الذي كان نتيجته اتفاقية دولية أنهت خلافاتِ سنين طوالٍ. فالحوارُ لغةٌ نادى بها دينُهُ، وتنَادى بها إنسانُ وطنهِ.
        </p>
        <Image width={'100%'} src={p24} />
    </>
  )
}

export default Kod21