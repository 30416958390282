import "./Prototype_Page.css"
import Player from "../Main_Comp/VideoPlayer"
import Pdf from "../../../pdf/Pdf"


function Page57() {
  
  return (
    <div className="container py-4 page">
      <h1 className="text-center">38_دراسة الشميسي</h1>
      <hr />
      <Pdf src={'1nb_S7-y_HG6vfr5xhONPuFiIZt8FdcR4'} title={'الملخص التنفيذي لمشروع الشميسي'} />
      {/* <hr /> */}
      <h1>عرض الملخص التنفيذي لمشروع الشميسي</h1>
      <Player src={'927934043?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write'}/>
      <hr />
      <h2 className="mb-4 text-info">* دراسة وقف الشميسي :</h2>
      <Pdf src={'105yQCYUjtTLKNrDi2UdC9u9aLNDXv33Z'} title={'وقف اقرأ'} /> <br />
      <Pdf src={'10HxZLWpP6TWTTeOx4D8SzXyaiOnH_kOu'} title={'وقف اقرأ - العرض المرئي'} />
      <hr />
    </div>
  )
}

export default Page57