import React from 'react'
import Slider from '../slider/Slider'
import p31 from '../../media/Content/Media/26.1.jpg'
import p32 from '../../media/Content/Media/26.2.jpg'


function Kod26() {
  return (
    <Slider height={''} images={[p31,p32]} />
  )
}

export default Kod26