import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import Slider from '../../slider/Slider'
import p1 from './مقالة الطريق الي الذهب بجريدة عكاظ.jpeg'
import p2 from './مقالة الطريق الي الذهب.jpeg'
import p3 from './شعار فروسية 1996م.jpg'
export default function Q5() {
  return (
    <Container>
      <Row>
        <Col xs={12} className='my-5' >
          <Slider images={[p1,p3]} />
        </Col>
        <Col xs={12} className='my-5 d-flex flex-column align-item-center ' >
          <h1 className='title text-dark mb-5 text-center'>المقالة</h1>
          <Image className='d-flex justify-content-center' src={p2} fluid thumbnail />
        </Col>
      </Row>
    </Container>
  )
}

