import React from 'react'
import Slider from '../slider/Slider'
import p40 from '../../media/Content/Media/34.1.jpg'
import p41 from '../../media/Content/Media/34.2.jpg'
import p42 from '../../media/Content/Media/34.3.jpg'
import VideoPlayer from '../video/VideoPlayer'
function Kod36() {
  return (
    <div>
        <Slider height={''} images={[p40,p41,p42]} />
        <VideoPlayer src={'927931562?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write'} />
    </div>
  )
}

export default Kod36