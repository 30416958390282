import React from 'react'
import Slider from '../slider/Slider'
import p36 from '../../media/Content/Media/25.3.jpg'
import p37 from '../../media/Content/Media/25.4.jpg'
import p38 from '../../media/Content/Media/25.5.jpg'
import p39 from '../../media/Content/Media/25.7.jpg'
function Kod33() {
  return (
    <Slider height={''} images={[p36,p37,p38,p39]} />
  )
}

export default Kod33