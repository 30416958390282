import React from 'react'
import VideoPlayer2 from '../video2/VideoPlayer2'

function Kod41() {
  return (
    <div>
        <p> 
            عاشَ الملكُ عبدالله مُتبنيًا مبدأَ التفاهمِ بالحُسنى، وعملَ على تقريبِ وجهاتِ النظرِ والنأيّ عن تصعيدِ الخلافاتِ، واضعًا نصبَ عينيهِ أنْ تكونَ مصلحةُ المواطنِ السعوديّ مِحورَ المعادلةِ الأهمّ في كلّ ما سعى إليه، وحملَ همومَ الأمةِ العربيةِ والإسلاميةِ في وجدانهِ رحمَ اللهُ عبدَه عبدالله  الذي آمنَ بربّهِ ،  وأخلصَ بعملهِ فحملَ لواءَ العروبةِ والإسلامِ محاورًا لحفظِ كرامةِ الإنسانِ ونشرِالسلامِ. وكما أوصانا سيبقى دائمًا في دعائِنا
        </p>
        <VideoPlayer2 src={'https://app.videas.fr/embed/media/e59282f6-8e3f-4aec-91a9-0f8e12e84d71/'} />
    </div>
  )
}

export default Kod41