import React from 'react'
import Pdf from '../../pdf/Pdf'
import { Container } from 'react-bootstrap'
function Q3() {
  return (
    <Container className='py-5' style={{minHeight:'40vh'}}>
        <Pdf title={' الورقة بصيغة pdf'} src = {'1RKpI31ZGPFpPEz2QD_cpEEpxCzdO6MtC'} />
    </Container>
  )
}

export default Q3