import i18next from "i18next";
import {initReactI18next } from "react-i18next";
import ar from './i18n/ar/translation.json'
import en from './i18n/en/translation.json'
i18next.use(initReactI18next).init({
    debug:true,
    fallbackLng:'ar',
    interpolation:{escapeValue:false},
    resources:{
        ar:{translation:ar},
        en:{translation:en}
    }
    // resources:{
    //     ar:{
    //         translation:{
    //             welcome:'اهلا',

    //         }
    //     },
    //     en:{
    //         translation:{
    //             welcome:'hello',

    //         }
    //     },
    // }
})
export default i18next