import "./Prototype_Page.css"

import Slider from "../Main_Comp/Slider"
import img_s1 from "../Content/79/51_عن رسل السلام/شعار برنامج رسل السلام 2.png"
import img_s2 from "../Content/79/51_عن رسل السلام/شعار برنامج رسل السلام.gif"

import Player from "../Main_Comp/VideoPlayer"
import Pdf from "../../../pdf/Pdf"
function Page79() {
  const images1 = [img_s1,img_s2]
      
  return (
    <div className="container py-4 page">
        <h1 className="text-center">51_عن رسل السلام</h1>
        <hr />
        <Pdf title={'تعريف بمشروع رسل السلام'} src={'1AiYjs2Q88cBveafVwNpNv1kbZUvi0fmO'}  /> 
        <hr />
        <h1>تعريف بمشرع رسل السلام</h1>
        <Player src={'927932836?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write'}/>
        <hr />
        <h1 className="text-center">شعار برنامج رسل السلام</h1>
        <Slider src={images1}/>

    </div>
  )
}

export default Page79