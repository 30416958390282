import "./Prototype_Page.css"
import { Image } from "react-bootstrap"

import img1 from "../Content/88/61_لوحة الملك عبدالعزيز (كتاب التوحيد)/لوحة الملك عبدالعزيز - كتاب  التوحيد.jpg"


function Page88() {
        
  return (
    <div className="container py-4 page">

        <Image src={img1} className="w-100"/>
                        
    </div>
  )
}

export default Page88