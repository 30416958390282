import React from 'react'
import Slider from '../slider/Slider'
import p26 from '../../media/Content/Media/page25.1.jpg'
import p27 from '../../media/Content/Media/page25.2.jpg'
import p28 from '../../media/Content/Media/page25.3.jpg'
import p29 from '../../media/Content/Media/page25.4.jpg'
import p30 from '../../media/Content/Media/page25.5.jpg'

function Kod25() {
  return (
    <Slider height={''} images={[p26,p27,p28,p29,p30]} />
  )
}

export default Kod25