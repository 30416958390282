import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import p2 from './1.jpg'
function Q20() {
  return (
    <Container>
      <Row>
        <Col xs={12} className='my-5' >
          <Image className='d-flex justify-content-center' src={p2} fluid thumbnail />
        </Col>
      </Row>
    </Container>
  )
}

export default Q20