import React, {  useEffect, useState } from 'react';
import { Button, Col, Container, Row} from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';

const FlipBook = () => {
  const [display,setDisplay] = useState('none')

  useEffect(()=>{
        const script = document.createElement('script')
        script.src = 'https://online.flippingbook.com/EmbedScriptUrl.aspx?m=redir&hid=801098'
        script.async = true
        script.defer = true
        document.body.appendChild(script)
        setDisplay('block')
    return ()=>{
      document.body.removeChild(script)
    }
  },[])
    const navigate = useNavigate()
    return (
    <Container fluid className='my-4'>
      <Row>
        <Col className='d-flex justify-content-center mb-md-3 mb-1  ' xs={'12'}>
          <Button style={{display:`${display}`}} className='fs-3' onClick={()=>navigate('/FlipBook-en')} > النسخة الانجليزية</Button>
        </Col>
        <Col className='d-flex justify-content-center sec-col'>
        <a  className='book-link' href="https://online.flippingbook.com/view/801098/1" class="fbo-embed" data-fbo-id="801098" data-fbo-lightbox="yes" data-fbo-width="1140px" data-fbo-height="780px" data-fbo-version="1" style= {{width:"100%"}}>كتاب التحويل المعرفي - فيصل بن عبدالله بن محمد آل سعود</a>
        </Col>
      </Row>
    </Container>
    );
}

export default FlipBook;
