import React from 'react'
import VideoPlayer2 from '../video2/VideoPlayer2'
import {Image} from 'react-bootstrap'
import p47 from '../../media/Content/Media/40.jpg'
function Kod40() {
  return (
    <div>
        <p>
        وها أنا أتشرفُ اليومَ بالمشاركةِ مع كوكبةٍ منْ رجالِ الفكرِ والتاريخِ والسياسةِ في هذا المحفلِ، خاتمًا كلمتي هذهِ ـ بعدَ الدعاءِ لخادمِ الحرمين الشريفين الملكِ عبد لله بنِ عبدالعزيز بالرحمةِ والمغفرةِ ـ أتقدمُ بالشكرِ أجزلِهِ للقائمين على تنظيمِ هذا المنتدى العلميّ لتناولِ  شخصيةٍ عالميةٍ تتمثلُ في الملكِ عبدالله بن عبدالعزيز، منْ آمنَ وعملَ وخدمَ الإسلامَ والمسلمين وحملَ رسالتَه إلى العالمين، أدعو اللهَ أنْ يتقبلَهُ معَ عبادهِ الصالحين ويسكنَه عليين. وإنّهُ ليشرفُني أنْ أطرزَ هذهِ الكلماتِ وأنهيها بتقديمِ عملٍ فنيّ لجداريةِ "ملك الحوار" لتنسجَ ذكراهُ في كلّ خيطٍ منها رمزًا لرسالتهِ الخالدةِ.
        </p>
        <VideoPlayer2 src={'https://app.videas.fr/embed/media/71080b38-3ea0-4c4e-94fb-a2bc295707e8/'} />
        <Image style={{width:'100%'}} src={p47} />
    </div>
  )
}

export default Kod40