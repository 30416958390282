import React from 'react'
import VideoPlayer2 from '../video2/VideoPlayer2'
import p22 from '../../media/Content/Media/18.jpeg'
import {Image} from 'react-bootstrap'

function Kod18() {
  return (
    <div>
        <VideoPlayer2 src={'https://app.videas.fr/embed/media/0549b599-bcfd-472c-8190-3cc8c7c2b545/'} />
        <br/>
        <Image src={p22} width={'100%'} height={''}/>
    </div>
  )
}

export default Kod18