import "./Prototype_Page.css"



import Pdf from "../../../pdf/Pdf"



function Page82() {
        
  return (
    <div className="container py-4 page">        
        <Pdf src={'1N-7PuSaULU6Vfrdcq9Yfb9BH3Z0G_x0f'}  />
        <hr />
        <Pdf src={'1Rrv5XoZjBKEog9ng78vindfj7Txi5G57'} title={'نبذة عن مشروع الأسرة المعرفية'} /> <br />
        <Pdf src={'1o2Fe7xXdwSzciDpP2VkvevAgUbFY3nZq'} title={'دراسة الأسرة المعرفية'} />
                
    </div>
  )
}

export default Page82