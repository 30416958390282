import "./Prototype_Page.css"
import Slider from "../../../slider/Slider"

import img_s1 from "../Content/60/40_الكتاب المهدي من الملك سلمان/صورة للملك سلمان.jpg"
import img_s2 from "../Content/60/40_الكتاب المهدي من الملك سلمان/إهداء الملك سلمان.jpg"
import img_s3 from "../Content/60/40_الكتاب المهدي من الملك سلمان/إهداء الملك سلمان 2.jpg"

function Page60() {
    const images1 = [img_s1,img_s2,img_s3]
  
  return (
    <div className="container py-4 page">
      <h1 className="text-center">40_الكتاب المهدي من الملك سلمان</h1>
      <hr />
      <Slider images={images1} height={''} />
      
    </div>
  )
}

export default Page60