import { Outlet,useLocation, useNavigate } from "react-router-dom"
import { Container,Image,Col,Row,Button } from 'react-bootstrap';
import p1 from '../../media/Content/Media/1.1.jpg'
import p2 from '../../media/Content/Media/1.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight,faArrowLeft } from '@fortawesome/free-solid-svg-icons';
function King() {
    const location  = useLocation();
    let navigate = useNavigate()
    let numbersOfLink = location.pathname.match(/\d+/g)
    const numbers = numbersOfLink ? parseInt(numbersOfLink.join(' ')) : null   
  return (
    <Container fluid>
        <h1>بسم الله الرحمن الرحيم</h1>
        <p className="col-md-12 col-md-12 col-md-12 text-center ">
            عليه توكلنا وبه نستعين، والصلاة والسلام على الأنبياء والمرسلين، وعلى سيدنا ونبينا وحبيبنا محمد أفضل الصلاة وأزكى التسليم              
        </p>
        <Row>
            <Col xs={12} md={6} >
                <Image thumbnail fluid rounded src={p1} className='my-3 w-100' style={{height:'65vh'}} />
            </Col>
            <Col xs={12} md={6} >
                <Image thumbnail fluid rounded src={p2} className='my-3 w-100' style={{height:'65vh'}}  />
            </Col>
        </Row>
            <Row >
                <Col xs={1} className='d-flex justify-content-start align-items-center'>
                    <Button style={{zIndex:'1'}} disabled={!numbers?true:false } className="d-flex" size="sm" variant="outline-success" onClick={()=>navigate(`${numbers===3?'./':`kod${numbers-1}`}`)} ><FontAwesomeIcon icon={faArrowRight} /></Button>
                </Col>
                <Col xs={10} style={{backgroundColor:'#fefefe'}} className='text-center p-4 fs-3 king_content my-3' >
                    <Outlet/>
                </Col>
                <Col xs={1} className='d-flex justify-content-end align-items-center' >
                    <Button disabled={numbers>=42?true:false} className="d-flex" size="sm" variant="outline-success" onClick={()=>navigate(`${!numbers?'kod3':`kod${numbers+1}`}`)} ><FontAwesomeIcon icon={faArrowLeft} /></Button>
                </Col>
            </Row>
        
    </Container>
  )
}

export default King