import React from 'react';
import Footer from './components/footer/Footer';
import Header from './components/header/Header';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import About from './pages/about/About';
import Bassmala from './pages/basmalla/Bassmala';
import {  Routes, Route } from 'react-router-dom';
import FlipBook from './pages/FlipBook/FlipBook';
import Qr from './pages/qr/Qr';
import FlipBookEn from './pages/FlipBook/FlipBookEn';
import King from './pages/king/King';
import Kod2 from './components/kod/Kod2';
import Kod3 from './components/kod/Kod3';
import Kod4 from './components/kod/Kod4';
import Kod5 from './components/kod/Kod5';
import Kod6 from './components/kod/Kod6';
import Kod7 from './components/kod/Kod7';
import Kod8 from './components/kod/Kod8';
import Kod9 from './components/kod/Kod9';
import Kod10 from './components/kod/Kod10';
import Kod11 from './components/kod/Kod11';
import Kod12 from './components/kod/Kod12';
import Kod13 from './components/kod/Kod13';
import Kod14 from './components/kod/Kod14';
import Kod15 from './components/kod/Kod15';
import Kod16 from './components/kod/Kod16';
import Kod17 from './components/kod/Kod17';
import Kod18 from './components/kod/Kod18';
import Kod19 from './components/kod/Kod19';
import Kod20 from './components/kod/Kod20';
import Kod21 from './components/kod/Kod21';
import Kod22 from './components/kod/Kod22';
import Kod23 from './components/kod/Kod23';
import Kod24 from './components/kod/Kod24';
import Kod25 from './components/kod/Kod25';
import Kod26 from './components/kod/Kod26';
import Kod27 from './components/kod/Kod27';
import Kod28 from './components/kod/Kod28';
import Kod29 from './components/kod/Kod29';
import Kod30 from './components/kod/Kod30';
import Kod31 from './components/kod/Kod31';
import Kod32 from './components/kod/Kod32';
import Kod33 from './components/kod/Kod33';
import Kod34 from './components/kod/Kod34';
import Kod35 from './components/kod/Kod35';
import Kod36 from './components/kod/Kod36';
import Kod37 from './components/kod/Kod37';
import Kod38 from './components/kod/Kod38';
import Kod39 from './components/kod/Kod39';
import Kod40 from './components/kod/Kod40';
import Kod41 from './components/kod/Kod41';
import Kod42 from './components/kod/Kod42';
import Book from './pages/book/Book';
function App() {
  const LaztCover = React.lazy(()=> import('./pages/cover/Cover') );
  const LaztHome = React.lazy(()=> import('./pages/home/Home') );
  const LazyTawnas = React.lazy(()=>import('./pages/tawnas/Page19'))
  return (
    <>
    <Header/>
      <Routes>
        <Route exact element={<React.Suspense fallback='Loading....'><LaztHome/></React.Suspense>} path='/'  />
        <Route exact element={<About/>} path='About'  />
        <Route exact element={<Bassmala/>} path='Basmalah'  />
        <Route exact element={<React.Suspense fallback="Loading..."> <LaztCover/> </React.Suspense>} path='Cover'  />
        <Route exact element={<FlipBook/>} path='FlipBook'  />
        <Route exact element={<FlipBookEn/>} path='FlipBook-en'  />
        <Route exact element={ <React.Suspense  > <LazyTawnas/> </React.Suspense> } path='TawNas'  />
        <Route element={<Qr/>} path='BCReferences' />
        <Route element={<Book title={'خطاب الأمير للملك بمقترح إنشاء فرع للجنادرية بالمنطقة العربية'} src={'1hOoKA4t-RPvzauK44HEAFsPAIcXl-0Tb'} />} path='king_of_Dialogue/letter_to_king.pdf' />
        <Route element={<Book title={'كلمة مؤتمر الشباب'} src={'1SX8dXitKc1ajlwk1hnTLlQbsZTnOq_0a'} />} path='king_of_Dialogue/Youth_conference.pdf' />
        <Route element={<Book title={'Jeddah Declaration'} src={'1qrF-lC-q_9cB9N3TnUbJHa8vvNoUk_9O'} />} path='king_of_Dialogue/Jeddah_Declaration.pdf' />
        <Route element={<King/>} path='King_Of_Dialogue/KOD' >
          <Route index element={<Kod2/>}  />
          <Route path='kod2' element={<Kod2/>}  />
          <Route path='kod3' element={<Kod3/>}  />
          <Route path='kod4' element={<Kod4/>}  />
          <Route path='kod5' element={<Kod5/>}  />
          <Route path='kod6' element={<Kod6/>}  />
          <Route path='kod7' element={<Kod7/>}  />
          <Route path='kod8' element={<Kod8/>}  />
          <Route path='kod9' element={<Kod9/>}  />
          <Route path='kod10' element={<Kod10/>}  />
          <Route path='kod11' element={<Kod11/>}  />
          <Route path='kod12' element={<Kod12/>}  />
          <Route path='kod13' element={<Kod13/>}  />
          <Route path='kod14' element={<Kod14/>}  />
          <Route path='kod15' element={<Kod15/>}  />
          <Route path='kod16' element={<Kod16/>}  />
          <Route path='kod17' element={<Kod17/>}  />
          <Route path='kod18' element={<Kod18/>}  />
          <Route path='kod19' element={<Kod19/>}  />
          <Route path='kod20' element={<Kod20/>}  />
          <Route path='kod21' element={<Kod21/>}  />
          <Route path='kod22' element={<Kod22/>}  />
          <Route path='kod23' element={<Kod23/>}  />
          <Route path='kod24' element={<Kod24/>}  />
          <Route path='kod25' element={<Kod25/>}  />
          <Route path='kod26' element={<Kod26/>}  />
          <Route path='kod27' element={<Kod27/>}  />
          <Route path='kod28' element={<Kod28/>}  />
          <Route path='kod29' element={<Kod29/>}  />
          <Route path='kod30' element={<Kod30/>}  />
          <Route path='kod31' element={<Kod31/>}  />
          <Route path='kod32' element={<Kod32/>}  />
          <Route path='kod33' element={<Kod33/>}  />
          <Route path='kod34' element={<Kod34/>}  />
          <Route path='kod35' element={<Kod35/>}  />
          <Route path='kod36' element={<Kod36/>}  />
          <Route path='kod37' element={<Kod37/>}  />
          <Route path='kod38' element={<Kod38/>}  />
          <Route path='kod39' element={<Kod39/>}  />
          <Route path='kod40' element={<Kod40/>}  />
          <Route path='kod41' element={<Kod41/>}  />
          <Route path='kod42' element={<Kod42/>}  />
          <Route path='*' element={<Kod42/>}  />
        </Route>
        <Route element={<Qr/>} path='*' />
      </Routes>
    <Footer/>
    </>
  );
}

export default App;
