import React from 'react'
import Slider from '../slider/Slider'
import p43 from '../../media/Content/Media/38.1.jpg'
import p44 from '../../media/Content/Media/38.2.jpg'
import p45 from '../../media/Content/Media/38.3.jpg'
function Kod38() {
  return (
    <Slider height={''} images={[p43,p44,p45]} />
  )
}

export default Kod38