import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import VideoPlayer from '../../video/VideoPlayer'
// import b1 from './تأهيل وتدريب الوافدين لدي شركات ومؤسسات القطاع الخاص والعام السعودي.pdf'

import Pdf from '../../pdf/Pdf'
// import v2 from './v2.mp4'
function Q19() {
  return (
    <Container className='my-5'>
      <Row>
        <Col xs={12}  className='my-5'>
          <VideoPlayer src={'928526644?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write'} />
        </Col>
        <Col xs={12}>
          <Pdf src={'1sC6xgDiawr2UthHNKWym_wNLo0JqVMmA'} title={'خطاب تقديم أبراهيم الصغير لدراسة تأهيل وتدريب الوافدين لدي شركات ومؤسسات القطاع الخاص والعام السعودي'} />
          <br/>
          <Pdf src={'1-ihi77nJi4Hxmek8gvhfF2OhF19X7h3y'} title={'مشروع دراسة واعداد برنامج للتأهيل والتوظيف بالقطاعات العسكرية'} />
        </Col>
        <br/>
        
       </Row>
    </Container>
  )
}

export default Q19