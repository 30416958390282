import React, { useState,useEffect } from 'react';
import { Carousel, CarouselItem, Image } from 'react-bootstrap';

const Slider = ({images,height}) => {
    const [isSmallScreen, setIsSmallScreen] = useState(false);

    // Function to update state based on window width
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768); // Example threshold for small screen
    };
  
    // Add event listener on component mount to handle window resize
    useEffect(() => {
      window.addEventListener('resize', handleResize);
      handleResize(); // Initial check on component mount
      return () => window.removeEventListener('resize', handleResize);
    }, []);
    const styles =()=>{
        return isSmallScreen?{height:height,with:'100%'}:{height:'100vh',with:'100%'}
    }
    console.log(styles())
    return (
        <>
        <Carousel fade data-bs-theme="dark" >
            {images.map((e,index)=>(
                <CarouselItem key={index} >
                    <Image style={isSmallScreen?{height:height,width:'100%'}:{height:'100vh',width:'100%'}} src={e} fluid thumbnail/>
                </CarouselItem>
                ))}
        </Carousel>
        </>
    );
}
Slider.defaultProps = {
    height:'80vh'
}
export default Slider;
