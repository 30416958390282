
import Player from "../../video/VideoPlayer"

function Page11_12_13() {
        
  return (
    <div className="container py-4 page">
        <h1> دمعة ملك_VP8</h1>
        <Player src={'928524654?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write'}/>
        <hr />
        <h1>خادم الحرمين الشريفين يستقبل منتخب الفروسية</h1>
        <Player src={'928524897?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write'}/>        
    </div>
  )
}

export default Page11_12_13