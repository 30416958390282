import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Slider from '../../slider/Slider'
import p1 from './01.jpg'
import p2 from './02.jpg'
import p3 from './03.jpeg'
import p4 from './04.jpeg'
function Q35() {
  return (
    <Container className='my-5'>
        <Row>
            <Col>
                <Slider images={[p1,p2,p3,p4]} />
            </Col>
        </Row>
    </Container>
  )
}

export default Q35