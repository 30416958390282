import React from 'react'
import Slider from '../slider/Slider'
import p15 from '../../media/Content/Media/6.13.jpg'
import p16 from '../../media/Content/Media/6.12.jpg'
import p17 from '../../media/Content/Media/6.11.jpg'
function Kod11() {
  return (
    <Slider height={''} images={[p15,p16,p17]} />
  )
}

export default Kod11