import React from 'react'
import {Image} from 'react-bootstrap'
import p8 from '../../media/Content/Media/6.4.jpg'

function Kod8() {
  return (
    <div>
        <p>
        كما أنّ لبنانَ منَ الخمسينيات إلى أوائلِ السبعينيات كان منارَ إشعاعٍ للثقافةِ العربيةِ، ومجمعًا للعقولِ على مُختلفِ المشَاربِ. وقد سمعْنا منه ـ يرحمه الله ـ أحاديثَ كثيرةً عنْ تجاربَ عاشها مع رجالِ الدينِ، والسياسةِ، والاقتصادِ، كما كانت له حواراتٌ معَ الأديبِ، والشاعرِ، والمفكرِ، والفنانِ، والصحفيِ. كلُّ ذلك أضافَ منظورًا أشملَ لشخصيةِ عبد الله بن عبد العزيز وعززّ تقبلَه للآخرِ، مُحترِمًا ومُقدّرًا إنسانيةَ الإنسانِ.
        </p>
        <Image style={{width:'100%'}} src={p8} />
    </div>
  )
}

export default Kod8