import "./Prototype_Page.css"
import Pdf from "../../../pdf/Pdf"
function Page76() {
      
  return (
    <div className="container-fluid py-4 page">
        <Pdf src={'1HUsAyf6EJUf-BU97chYB1QZp5gpZQgDv'} name={'مسيرة التعليم'}/>
    </div>
  )
}

export default Page76