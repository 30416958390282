import React from 'react'
import Slider from '../slider/Slider'
import p13 from '../../media/Content/Media/10.1.jpg'
import p14 from '../../media/Content/Media/10.2.jpg'

function Kod10() {
  return (
    <Slider height={''} images={[p13,p14]} />
  )
}

export default Kod10