import "./Prototype_Page.css"
import { Image } from "react-bootstrap"


import img1 from "../Content/83/58_مجسم إقرأ/مجسم اقرأ.jpeg"
import Pdf from "../../../pdf/Pdf"


function Page83() {
        
  return (
    <div className="container py-4 page">
        
        <Pdf src={'1m50Fg6obSCXQCNZisFMTnpZF70bmskZY'} title={'خطاب خادم الحرمين'} />
        <hr />
        <h1 className="text-center">58_مجسم إقرأ</h1>
        <Image src={img1} className="w-100"/>
                
    </div>
  )
}

export default Page83