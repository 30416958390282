import React from 'react'

function VideoPlayer2({src}) {
  return (
    <div className="d-flex justify-content-center video-responsive">
      <iframe
        src={src}
        width="560"
        height="315"
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
        title="Vimeo video player"
      ></iframe>
    </div>
  )
}

export default VideoPlayer2