import React from 'react'
import { Table } from 'react-bootstrap'
import qrData from '../../qrData'
function Ref() {
  return (
    <Table striped bordered hover  >
        <thead>
        <tr>
          <th>#</th>
          <th>رقم الصفحة</th>
          <th>رقم الاستجاية</th>
          <th>المحتوى</th>
          <th>الرابط</th>
        </tr>
            {qrData.map((e,index)=>{
                return(
                    <tr key={index} >
                        <td> <a href={e.url} className='text-decoration-none text-dark' >{index+1}</a>  </td>
                        <td> <a href={e.url} className='text-decoration-none text-dark' >{e.page_no} </a>  </td>
                        <td> <a href={e.url} className='text-decoration-none text-dark' >{e.refrence_no} </a>  </td>
                        <td> <a href={e.url} className='text-decoration-none text-dark'>{e.title}</a>  </td>
                        <td> <a href={e.url}>tkt.com{e.url}</a> </td>
                    </tr>
                )
            })}
        
      </thead>
    </Table>
  )
}

export default Ref