import "./Prototype_Page.css"
import Slider from "../../../slider/Slider"
import img_s1 from "../Content/65/42_صور الملك عبدالله وهو يعاين لأول مرة موقع الجامعة المقترح/01.JPG"
import img_s2 from "../Content/65/42_صور الملك عبدالله وهو يعاين لأول مرة موقع الجامعة المقترح/02.JPG"
import img_s3 from "../Content/65/42_صور الملك عبدالله وهو يعاين لأول مرة موقع الجامعة المقترح/03.JPG"
import img_s4 from "../Content/65/42_صور الملك عبدالله وهو يعاين لأول مرة موقع الجامعة المقترح/04.JPG"
import Pdf from "../../../pdf/Pdf"

function Page65() {
    const images1 = [img_s1,img_s2,img_s3,img_s4]
  
  return (
    <div className="container py-4 page">
        <h1 className="text-center">42_صور الملك عبدالله وهو يعاين لأول مرة موقع الجامعة المقترح</h1>
        <hr />
        <Slider height={''} images={images1}/>
        <hr />
        <Pdf src={'121YphfN4YNmOkblUeMqCdZh0hnlMe-5D'} title={'كلمة الأمير حلم يتحقق'} /> <br />
        <Pdf src={'1SqWAO5AXyLFoB43hcu9Sftmcjp1RmOPU'} title={'خطاب الامير لخادم الحرمين بإنشاء هيئة عليا وطنية للمعرفة والعلوم والتقنية والثقافة'} />
        

    </div>
  )
}

export default Page65