import React from 'react'
import Slider from '../slider/Slider'
import p9 from '../../media/Content/Media/6.6.jpg'
import p10 from '../../media/Content/Media/6.7.jpg'
import p11 from '../../media/Content/Media/6.3.jpg'
import p12 from '../../media/Content/Media/6.5.jpg'
function Kod9() {
  return (
    <Slider height={''} images={[p9,p10,p11,p12]} />
  )
}

export default Kod9