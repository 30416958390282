import React from 'react'
import Pdf from '../pdf/Pdf'

function Kod35() {
  return (
    <div>
        <p>  
        كما تشرفتُ عندما كنتُ مسؤوًلا عنْ جمعيةِ الكشافةِ العربيةِ السعوديةِ وسعيتُ ليتحققَ دورُ" رسل السلام " البرنامجِ الكشفي العالميّ الذي دعا إليه و أسسّهُ الملكُ عبدالله بن عبدالعزيز و الملكُ كارل قوستاف ملكُ السويد ودشن في جامعةِ الملكِ عبدالله للعلومِ والتقنيةِ ومدينةِ الملكِ عبدالله الاقتصادية في عام ٢٠١١م ، ليرتبطَ هذا البرنامجُ الإنسانيُّ العالميُّ برسالةِ مركزِ الملكِ عبدالله العالمي للحوارِ بين أتباعِ الأديانِ و الثقافاتِ، بتوقيعِ اتفاقيةِ شراكةٍ يحملُ  عبرَها شبابُ العالمِ رسالتَه الإنسانيةَ بالعملِ التطوعيّ منْ خلالِ ثلاثين مليون كشافٍ قاموا إلى الان بأكثرَ منْ بليون وثلاثمائة مليون ساعة عملٍ تطوعيّ. كما كان لي شرفُ تمثيلِ المملكةِ العربيةِ السعوديةِ في منظمةِ اليونيسكو وتقدمتُ بمقترحٍ لتفعيلِ المشاركةِ بينَ شبابِ العالمِ في العملِ التطوعيّ ورسالةِ الحوارِ. وكانَ المؤتمرُ الدوليُّ الأولُ للشبابِ في جدةَ عام ٢٠١٣م تحتَ مظلةِ برنامجِ الملكِ عبدالله لحوارِ الثقافاتِ في المنظمةِ الدوليةِ اليونيسكو. وانتهى المجتمعون منْ شبابِ العالمِ إلى إعلانِ جدة الذي يدعو إلى العملِ التطوعي والحوارِ. 
        </p>
        <Pdf title={'كلمة مؤتمر الشباب'} src={'1nvA7prFv6hOEOibtTQEyYbuDNFSfBCxn'} />
        <br/>
        <Pdf title={'إعلان جدة'} src={'1XCXsbaLO1JQZF56WXCl3DDElmDyzCCVM'} />
    </div>
  )
}

export default Kod35