import { Image } from "react-bootstrap"


import Player from "../../video/VideoPlayer"
import img1 from "./The Last Horsman.jpg"
import Pdf from "../../pdf/Pdf"


function Page14_15_16_17() {
        
  return (
    <div className="container py-4 page">
        
        <h1>01 - The Last Horseman EPK_VP8</h1>
        <Player src={'928363410?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write'}/>
        <hr />
        <h1>02 - See The Young Horses_VP8</h1>
        <Player src={'928085315?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write'}/>
        <hr />
        <h1>03 - Missing Part Of Me_VP8</h1>
        <Player src={'928068543?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write'}/>
        <hr />
        <h1>04 - They Fly Without Wings_VP8</h1>
        <Player src={'927940092?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write'}/>
        <hr />
        <h2 className="pb-5 text-info">* مراجع :</h2>
        <Pdf src={'1ZhVKar6H3v7k69K5wyagchf2-oTaADND'} title={'- ملخص قصة مسرحية آخر الفرسان'} /> <br />
        <Pdf src={'1Mgth40jv6LuFFnuWgACi4qPXFpgI6Tpn'} title={'- مطوية مسرحية آخر الفرسان'} />    <br />
        <Pdf src={'16_VlOuo4z742_O7mof2gGMoDKt5PX7JJ'} title={'- سمو وزير التربية والتعليم يدشن عرض مسرحية _آخر الفرسان_ وكالة الأنباء السعودية'} /> <br />
        <Pdf src={'1t3ms4pu0lYcUENbM2Nd4VdzPH6Afyohm'} title={'- الملكة صوفيا حرم العاهل الإسباني تفتتح مسرحية آخر الفرسان - جريدة الشرق الأوسط'} />  <br />
        <Pdf src={'1Mgth40jv6LuFFnuWgACi4qPXFpgI6Tpn'} title={'- آخر الفرسان مسرحية تروي سيرة توحيد السعودية العربية.نت _ الصفحة الرئيسية'} />      <br />
        <hr />
        <h1 className="text-center">The Last Horsman</h1>
        <br />
        <Image src={img1} className="w-100"/>
        
                        
    </div>
  )
}

export default Page14_15_16_17