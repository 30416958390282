import React from 'react'
import Slider from '../slider/Slider'
import p20 from '../../media/Content/Media/12.jpg'
import p21 from '../../media/Content/Media/12.1.jpg'

function Kod16() {
  return (
    <div>
        <p>  
        بعدَ جمعِ الكلمةِ، ومحاولةِ توحيدِ الصّفِ حولَ بيتِ الله الحرامِ، حيثُ كانَ ـ يرحمه المولى ويحسنُ سُكناه - يتأملُ دائماً ويقولُ وهو يرى جُموعَ المسلمين حولَ الكعبةِ المُشرفةِ: "إذا أشكلَ على الإنسانِ شيءٌ، ورأى هذه الجموعَ كتفا بكتفٍ حول بيتِ الله العتيقِ بشكلٍ دائري مُتكاملٍ، مؤمنةً متعبدةً بحوارِها مع خالقِها، فلا بد أنَّ هناك شيئًا عظيمًا يجمعُهم ويوحدُ كلمتَهم لحلّ مشاكلِهم ". وهنا أذكرُ مبادرَته التاريخيةَ بجمعِ الإخوةِ الفلسطينيين الرئيس الفلسطيني محمود عباس وإسماعيل هنيّة رئيسِ منظمةِ حماس في مكةَ المكرمة للحوارِ والتصالحِ، وقد وقفَ بينَهما في قصر الصفا مُشيرًا بيده نحو الكعبة الشريفة قائلا لهما "إخواني إذا أشكل عليكم شيءٌ أو اعترض طريقَ حواركم، فكلُّ ما أطلبه منكم أن تراقبوا الله في أنفسكم وتنظروا من خلال هذه النافذة إلى بيت الله الحرام وتعتبروا بأن من جمع هذه الأمةَ بصفوف موحدة في هذا المكان غيرُ عاجزٍ عن توحيد أهدافِكم ورسالتِكم وجمعِ كلمتكم".
        </p>
        <Slider images={[p20,p21]} />
    </div>
  )
}

export default Kod16