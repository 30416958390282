import { useEffect } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { useTranslation } from 'react-i18next';

function DropBtn() {
  const {i18n,t} = useTranslation()
  console.log(i18n.dir());
  const languages = [
    {code:'ar',lang:'العربية'},
    {code:'en',lang:'English'}
  ]
  const changeLanguage = (language)=>{
    i18n.changeLanguage(language)
  }
  useEffect(()=>{
    document.body.style.direction =i18n.dir()
  },[i18n,i18n.language])
  return (
    <Dropdown>
      <Dropdown.Toggle variant="success" id="dropdown-basic">
        {t('drop-title')}
      </Dropdown.Toggle>

      <Dropdown.Menu>
      {languages.map((e)=>{
      return (
        <Dropdown.Item active={e.code===i18n.language?true:false} onClick={()=>changeLanguage(e.code)} className={e.code===i18n.language ?'':'text-dark'} key={e.code} > {e.lang} </Dropdown.Item>
        )
      })}
        {/* <Dropdown.Item active className='text-dark ' href="#/action-1">العربية</Dropdown.Item>
        <Dropdown.Item className='text-dark ' href="#/action-2">الانجليزية</Dropdown.Item> */}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default DropBtn;