import React from 'react'

function Kod42() {
  return (
    <div>
      <h1 className='mb-4'>الفهرس</h1>
        <a href="./" className="fs-3 text-dark text-decoration-none ">
             1.	 منسوجة " الدعاء"  للملك عبدالله، معلقة في متحف جامعة الملك عبدالله للعلوم والتقنية. مبنية على صورة من تصوير فيصل بن عبدالله آل سعود.</a>
            <br /> <a href="KOD3" className="fs-3 text-dark text-decoration-none ">
           2.	الملك عبدالله يحمل الكاميرا، مركز توثيق سيرة الملك عبدالله بن عبدالعزيز.</a>
 <br /> <a href="KOD4" className="fs-3 text-dark text-decoration-none ">
            3.	غلاف وعرض كتاب شروق وغروب، تصوير فيصل بن عبدالله آل سعود، أرشيف ليان الثقافية.</a>
 <br /> <a href="KOD5" className="fs-3 text-dark text-decoration-none ">
            4.	صورة الملك عبدالله وهو يحاور الفرس</a>
 <br /> <a href="KOD5" className="fs-3 text-dark text-decoration-none ">
            5.	صورة الملك عبدالله وهو يدعو ربه وفوق رأسه صورة والده الملك المؤسس العظيم. تصوير فيصل بن عبدالله آل سعود. أرشيف ليان الثقافية.  </a>
 <br /> <a href="KOD7" className="fs-3 text-dark text-decoration-none ">
            6.	الملك عبدالله في حواراته مع النخب اللبنانية في الستينات. مركز توثيق سيرة الملك عبدالله بن عبدالعزيز.</a>
 <br /> <a href="KOD12" className="fs-3 text-dark text-decoration-none ">
            7.	 فيديو بمجلس الملك عبدالله بالطائف في التسعينات وهو يتحدث إلى المواطنين عن حلمه بإقامة مدينة علمية بالطائف.</a>
 <br /> <a href="KOD13" className="fs-3 text-dark text-decoration-none ">
            8.	پوستر جامعة الملك عبدالله للعلوم والتقنية للفنان المغربي يوسف الدويب، مؤطره بصور مراحل تأسيس الجامعة من البداية إلى إفتتاحها تصوير فيصل بن عبدالله آل سعود، وكذلك صور أول دفعة تتخرج من الجامعة. وفيديو قصيدة " إروها سيدي"  بمناسبة إفتتاح الجامعة. أرشيف ليان الثقافية.</a>
 <br /> <a href="KOD14" className="fs-3 text-dark text-decoration-none ">
            9.	 كلمة الملك عبدالله للمواطنين بمناسبة إعلان قيام مركز الملك عبدالعزيز للحوار الوطني. مركز توثيق سيرة الملك عبدالله بن عبدالعزيز.</a>
 <br /> <a href="KOD14" className="fs-3 text-dark text-decoration-none ">
            10.	الرسالة المقدمة للملك عبدالله من الحرس الوطني بالقطاع الغربي، بمقترح إنشاء مركز يخدم رسالة مهرجان الجنادرية للحوار.</a>
 <br /> <a href="KOD15" className="fs-3 text-dark text-decoration-none ">
            11.	" بلاغ مكة " في المؤتمر الإسلامي، موقع منظمة التضامن الإسلامي.</a>
 <br /> <a href="KOD16" className="fs-3 text-dark text-decoration-none ">
            12.	كرسي الملك عبدالله في مصلاه بقصر الصفا بمكة المكرمة، وهو يصلي وبجانبه الأمير خالد الفيصل، تصوير فيصل بن عبدالله آل سعود. أرشيف ليان الثقافية.</a>
 <br /> <a href="KOD18" className="fs-3 text-dark text-decoration-none ">
            13.	كلمة الملك عبدالله وفيديو كلمة الملك عبدالله في مؤتمر القمة العربية بيروت ٢٠٠٢م.  مركز توثيق سيرة الملك عبدالله بن عبدالعزيز.</a>
 <br /> <a href="KOD19" className="fs-3 text-dark text-decoration-none ">
            14.	ملخص "مشروع الشميسي"، أرشيف مجموعة الأغر و صورة مع الملك عبدالله لأعضاء مجموعة الأغر عند تقديم "مشروع الشميسي". أرشيف مجموعة الأغر. و فيديو " مشروع الشميسي"،  أرشيف مجموعة الأغر .</a>
 <br /> <a href="KOD21" className="fs-3 text-dark text-decoration-none ">
            15.	مجسم إتفاقية ترسيم الحدود مع اليمن، مركز توثيق سيرة الملك عبدالله بن عبدالعزيز.</a>
 <br /> <a href="KOD22" className="fs-3 text-dark text-decoration-none ">
            16.	صورة مقابلة الملك عبدالله في مسجد شيان مع مسلمي الصين ١٩٩٨م. تصوير فيصل بن عبدالله ال سعود. أرشيف ليان الثقافية.</a>
    <br /> <a href="KOD23" className="fs-3 text-dark text-decoration-none ">
            17.	صورة الملك عبدالله وأحمدي نجاد رئيس الجمهورية الإسلامية الإيرانية مؤتمر مكة عام ٢٠١٢م.  مركز توثيق سيرة الملك عبدالله بن عبد العزيز.</a>
 <br /> <a href="KOD24" className="fs-3 text-dark text-decoration-none ">
            18.	فيديو وصور زيارة الملك عبدالله للأحياء الفقيرة. مركز توثيق سيرة الملك عبدالله بن عبدالعزيز.</a>
 <br /> <a href="KOD27" className="fs-3 text-dark text-decoration-none ">
            19.	صورة وكلمة الملك عبدالله للمؤتمر الدولي للإرهاب الذي دعى إليه بالرياض عام ٢٠٠٥م. مركز توثيق سيرة الملك عبدالله بن عبدالعزيز.</a>
 <br /> <a href="KOD29" className="fs-3 text-dark text-decoration-none ">
            20.	صورتا الملك عبدالله وهو يمسك بتمرة وبجانبه يظهر على الشاشة مقولته " الدين ثم الوطن والصبر والعمل " وكذلك وهو يتحدث إلينا وبجانبه الأمير خالد الفيصل في مكة المكرمة في مجلسه بقصر الصفا قبل إفطار رمضان المبارك عام ١٤٣١هـ.  تصوير فيصل بن عبدالله  آل سعود و لوحة  "ملك الحوار"  التي عرضت في مؤتمر القمة الإسلامي بمكة المكرمة عام ٢٠١٢م يقف بجوارها راسمها الفنان ضياء عزيز ضياء وفيصل بن معمر وفيصل بن عبدالله آل سعود. أرشيف ليان الثقافية.</a>
 <br /> <a href="KOD30" className="fs-3 text-dark text-decoration-none ">
            21.	 صورة الملك عبدالله مع عمدة نيويورك بلومبيرغ بعد إلقاء كلمته عن الحوار في منظمة الأمم المتحدة واجتماعه مع ممثلي الإديان. مركز توثيق سيرة الملك عبدالله بن عبدالعزيز. </a>
 <br /> <a href="KOD31" className="fs-3 text-dark text-decoration-none ">
            22.	فيديو زيارة الملك عبدالله للمعرض في مبنى هيئة الأمم المتحدة.</a>
 <br /> <a href="KOD32" className="fs-3 text-dark text-decoration-none ">
            23.	فيديو الكلمة الملقاة في مركز الملك عبدالله العالمي للحوار بين اتباع الأديان والثقافات ( كايسيد ) ٢٠١٣م، والصور المرافقة. أرشيف مركز الملك عبدالله بفينا.</a>
 <br /> <a href="KOD34" className="fs-3 text-dark text-decoration-none ">
            24.	فيديو " رؤية ملك"  رسل السلام.  وصور الملك عبدالله مع ملك السويد كارل قوستاف لإعلان مشروع رسل السلام عام ٢٠١١م. أرشيف جمعية الكشافة السعودية.</a>
 <br /> <a href="KOD35" className="fs-3 text-dark text-decoration-none ">
            25.	إعلان جدة، وكلمة المؤتمر الدولي الأول للشباب. </a>
 <br /> <a href="KOD36" className="fs-3 text-dark text-decoration-none ">
            26.	فيديو لوحة " ملك الحوار". أرشيف ليان الثقافية.  </a>
               <br /> <a href="KOD39" className="fs-3 text-dark text-decoration-none ">
            27.	صورة الملك عبدالله وهو منسف الغتره و صورة من الخطابين ، تصوير فيصل بن عبدالله آل سعود. </a>
 <br /> <a href="KOD40" className="fs-3 text-dark text-decoration-none ">
            28.	فيديو منسوجة " ملك الحوار". أرشيف ليان الثقافية.</a>
 <br /> <a href="KOD41" className="fs-3 text-dark text-decoration-none ">
            29.	فيديو لا تنسوني من دعائكم . مركز توثيق سيرة الملك عبدالله بن عبدالعزيز.</a>
    </div>
  )
}

export default Kod42