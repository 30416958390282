import React from 'react'
import { Container } from 'react-bootstrap'
import Pdf from '../../pdf/Pdf'

function Q4() {
  return (
    <Container className='my-5' style={{minHeight:'40vh'}}>
        <Pdf name={'على منعطف مهم كتاب الانسانية'} src = {`1J2i9HoEVc20TiNi1bKWDHdSZFGW9DEI_`} />
    </Container>
  )
}

export default Q4