import React from 'react'
import { Container, Image } from 'react-bootstrap'
import p1 from './P1.png'
function Q26() {
  return (
    <Container>
        <Image src={p1} style={{width:'100%'}} className='my-5'  />
    </Container>
    
  )
}

export default Q26