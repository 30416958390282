import React, {  useEffect, useState } from 'react';
import { Button, Col, Container, Row} from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';


const FlipBookEn = () => {
  const [display,setDisplay] = useState('none')
  useEffect(()=>{
    const script = document.createElement('script')
    script.src = 'https://online.flippingbook.com/EmbedScriptUrl.aspx?m=redir&hid=1057380145'
    script.async = true
    script.defer = true
    document.body.appendChild(script)
    setDisplay('block')
    return ()=>{
      document.body.removeChild(script)
    }
  },[])
  const navigate = useNavigate()
    return (
        
    <Container style={{direction:'ltr !important'}} fluid className='my-4'>
      <Row>
        <Col className='d-flex justify-content-center mb-md-4 mb-1 ' xs={'12'}>
          <Button className='fs-3' style={{display:`${display}`}} onClick={()=>navigate('/FlipBook')} > arabic version</Button>
        </Col>
        <Col className='d-flex justify-content-center'>
        <a href="https://online.flippingbook.com/view/1057380145/" class="fbo-embed" data-fbo-id="801098" data-fbo-lightbox="yes" data-fbo-width="1140px" data-fbo-height="780px" data-fbo-version="1" style= {{width:"100%"}}>كتاب التحويل المعرفي - فيصل بن عبدالله بن محمد آل سعود</a>
        </Col>
      </Row>
    </Container>
    );
}

export default FlipBookEn;
