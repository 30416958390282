import React from 'react';
import './footer.css'
const Footer = () => {
    return (
        <footer className='text-center py-3'>
            <h3> كتاب التحول المعرفي / فيصل بن عبدالله بن محمد آل سعود - © 2024 </h3>
        </footer>
    );
}

export default Footer;
