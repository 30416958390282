import React from 'react'

function Audio({src,title}) {
  return (
    <div className='my-3'>
    <h1>{title}</h1>
    <audio controls>
        <source src={src} type="audio/mpeg" />
        Your browser does not support the audio element.
    </audio>
</div>
  )
}

export default Audio