import React from 'react'
import Q3 from './components/qr-components/q3/Q3'
import Q5 from './components/qr-components/q5/Q5'
import Q4 from './components/qr-components/q4/Q4'
import Q11 from './components/qr-components/q11/Q11'
import Q14 from './components/qr-components/q14/Q14'
import Q18 from './components/qr-components/q18/Q18'
import Q19 from './components/qr-components/q19/Q19'
import Q20 from './components/qr-components/q20/Q20'
import Q21 from './components/qr-components/q21/Q21'
import Q22 from './components/qr-components/q22/Q22'
import Q23 from './components/qr-components/q23/Q23'
import Q26 from './components/qr-components/q26/Q26'
import Q28 from './components/qr-components/q28/Q28'
import Q29 from './components/qr-components/q29/Q29'
import Q30 from './components/qr-components/q30/Q30'
import Q31 from './components/qr-components/q31/Q31'
import Q32 from './components/qr-components/q32/Q32'
import Q35 from './components/qr-components/q35/Q35'
import Page57 from './components/qr-components/rest of pages/Pages/Page57'
import Page59 from './components/qr-components/rest of pages/Pages/Page59'
import Page60 from './components/qr-components/rest of pages/Pages/Page60'
import Page64 from './components/qr-components/rest of pages/Pages/Page64'
import Page65 from './components/qr-components/rest of pages/Pages/Page65'
import Page66 from './components/qr-components/rest of pages/Pages/Page66'
import Page67 from './components/qr-components/rest of pages/Pages/Page67'
import Page68 from './components/qr-components/rest of pages/Pages/Page68'
import Page69 from './components/qr-components/rest of pages/Pages/Page69'
import Page70 from './components/qr-components/rest of pages/Pages/Page70'
import Page71 from './components/qr-components/rest of pages/Pages/Page71'
import Page76 from './components/qr-components/rest of pages/Pages/Page76'
import Page79 from './components/qr-components/rest of pages/Pages/Page79'
import Page80 from './components/qr-components/rest of pages/Pages/Page80'
import Page81 from './components/qr-components/rest of pages/Pages/Page81'
import Page82 from './components/qr-components/rest of pages/Pages/Page82'
import Page83 from './components/qr-components/rest of pages/Pages/Page83'
import Page87 from './components/qr-components/rest of pages/Pages/Page87'
import Page88 from './components/qr-components/rest of pages/Pages/Page88'
import Page89 from './components/qr-components/rest of pages/Pages/Page89'
const Lazy17 = React.lazy(()=>import('./components/qr-components/q7/Q7'))
const Lazy33 = React.lazy(()=>import('./components/qr-components/q24/Q24'))
const Lazy39 = React.lazy(()=>import('./components/qr-components/q27/Q27'))
const Lazy52 = React.lazy(()=>import('./components/qr-components/rest of pages/Pages/Q36'))
const Lazy72 = React.lazy(()=>import('./components/qr-components/rest of pages/Pages/Page72'))
const primData = [
    {
     "refrence_no": 3,
     "page_no": 9,
     "title": "الورقة التي نوقشت في ستانفورد عن الخطة الخمسية الثانية",
     "content": <Q3/>
    },
    {
     "refrence_no": 4,
     "page_no": 11,
     "title": "نسخة من كتاب الانسانية على منعطف مهم ",
     "content": <Q4/>
    },
    {
     "refrence_no": 5,
     "page_no": 16,
     "title": "مقالة (الطريق الي الذهب ) رياضة و شباب 27 يوليو 1996 م ",
     "content":<Q5/>
    },
    {
     "refrence_no": 6,
     "page_no": 16,
     "title": "شعار فروسية 1996 ",
     "content":<Q5/>
    },
    {
     "refrence_no": 7,
     "page_no": 17,
     "title": "صورة اول مجلس ادارة للإتحاد السعودي للفروسية حين تشرف بمقابلة الملك عبدالله- يرحمه الله",
     "content": <React.Suspense fallback='Loading...' > <Lazy17/> </React.Suspense>
    },
    {
     "refrence_no": 8,
     "page_no": 17,
     "title": "لوحة الفرسان للفنان ضياء عزيز ضياء ",
    },
    {
     "refrence_no": 9,
     "page_no": 17,
     "title": "صورة الفارس خالد العيد "
    },
    {
     "refrence_no": 10,
     "page_no": 17,
     "title": "صندوق الفروسية | فيديو صندوق الفروسية السعودية .. رؤية ملك | شعار صندوق الفروسية | فيديو وصف شعار صندوق الفروسية "
    },
    {
     "refrence_no": 11,
     "page_no": 18,
     "title": "قصيدة دمعة ملك | فيديو قصيدة دمعة ملك | فيديو مقابلة الملك عبدالله - يرحمه الله - للفرسان بعد أول انجازات صندوق الفروسية ",
     "content":<Q11/>
    },
    {
     "refrence_no": 12,
     "page_no": 18,
     "title": "كتاب وفيديو معرض هبة من الصحراء - كنتاكي - أمريكا | فيديو افتتاح معرض هبة من الصحراء | فيديو معرض هبة من الصحراء بكنتاكي - التلفزيون السعودي | فيديو الطريق الى لندن 1-2 | كتاب وفيديو معرض ( من الجزيرة العربية الى أسكوت الملكي ) المتحف البريطاني| دليل معرض (الخيل من الجزيرة العربية الى سباقات أسكوت)  المتحف البريطاني| مختصر معرض (الخيل من الجزيرة العربية الى سباقات أسكوت ) المتحف البريطاني | دليل المتحف البريطاني عن العرض | فيديو( الخيل من الجزيرة العربية الى سباقات أسكوت ) المتحف البريطاني - لندن 2012 قناة الأخبار ",
     "Column5": "توحيد اسم الكتاب هبة من الصحراء - توحيد اسم سباقات أسكوت "
    },
    {
     "refrence_no": 13,
     "page_no": 18,
     "title": "فيديو تكريم الفرسان بالفوز بالميدالية البرونزية في دورة الألعاب الأولمبية بلندن 2012| فيديو استقبال الملك عبدالله - يرحمه - الله للفرسان وتتويجهم بوسام الملك عبدالعزيز بعد حصولهم على الميدالية البرونزية في أولمبياد لندن عام 2012"
    },
    {
     "refrence_no": 14,
     "page_no": 19,
     "title": "(آخر الفرسان) مسرحية تروي سيرة توحيد السعودية - العربية نت |مطوية مسرحية (آخر الفرسان) | ملخص قصة مسرحية (آخر الفرسان) | الملكة صوفيا حرم العاهل الإسباني تفتتح مسرحية ( آخر الفرسان) | فيصل بن عبدلله يدشن عرض (آخر الفرسان ) وكالة الأنباء السعودية | فيديو (آخر الفرسان) 1-2-3-4",
     "content":<Q14/>
    },
    {
     "refrence_no": 15,
     "page_no": 19,
     "title": "مخطوطة الخيل  لعباس باشا | لوحة الملوك للفنان ضياء عزيز ضياء "
    },
    {
     "refrence_no": 16,
     "page_no": 19,
     "title": "بطولة كأس الأمم للفروسية (فروسية) | مقالة(صندوق الفروسية السعودي يرعى بطولة أوروبا)  جريدة الرياض |عرض بطولة كأس الأمم للفروسية (فروسية)"
    },
    {
     "refrence_no": 17,
     "page_no": 19,
     "title": "دراسة مقر الفروسية بالثمامة"
    },
    {
     "refrence_no": 18,
     "page_no": 22,
     "title": "مجسم العلم والإيمان والعمل",
     "content":<Q18/>
    },
    {
     "refrence_no": 19,
     "page_no": 23,
     "title": "إفتتاح مركز التدريب في الحرس الوطني | فيديو حفل وضع حجر الأساس لكتيبة الأمن الخاص الثانية وتدشين مركز تدريب الحرس الوطني بالقطاع الغربي - الأربعاء 16 مارس 2003 م الأجزاء 1-2" ,
     "content":<Q19/>
    },
    {
     "refrence_no": 20,
     "page_no": 26,
     "title": "صورة اللواء متعب بن طافور مع الملك عبدالله بن عبد العزيز - يرحمه الله ",
     "content": <Q20/>
    },
    {
     "refrence_no": 21,
     "page_no": 29,
     "title": "شعار الصخرة وتوحيد الجزيرة العربية (مبني الحرس الوطني بجدة)",
     "content": <Q21/>    },
    {
     "refrence_no": 22,
     "page_no": 31,
     "title": "تسجيل ندوة مشروع الشرائع  حول مفهوم البناء والتشغيل والإعادة  (BOT) ",
     'content':<Q22/>
    },
    {
     "refrence_no": 23,
     "page_no": 32,
     "title": "مختصر كتاب (التوحيد) | فيديو افتتاح معرض (التوحيد) بجدة ومقابلة التلفزيون السعودي | فيديو فلم معرض (التوحيد) | ملفات صوت أوبريت (التوحيد) ",
     "content":<Q23/>
    },
    {
     "refrence_no": 24,
     "page_no": 33,
     "title": "محاضرة بعنوان (المملكة في عيون المحبين) + صور ",
     'content':<React.Suspense fallback='Loading ...' ><Lazy33/></React.Suspense>
    },
    {
     "refrence_no": 25,
     "page_no": 33,
     "title": "خطاب مقترح المركز الثقافي في القطاع الغربي للحرس الوطني "
    },
    {
     "refrence_no": 26,
     "page_no": 37,
     "title": "شعار مرور 50 عام على تأسيس رئاسة الاستخبارات العامة في المملكة العربية السعودية ",
     "content" : <Q26/>
    },
    {
     "refrence_no": 27,
     "page_no": 39,
     "title": "مشروع حي خزام| محضر اجتماع مشروع خزام | مقترح موقع مقر مقر منظمة التعاون الإسلامي| فيديو مشروع قصر خزام ",
     "content": <React.Suspense fallback='Loading ...' ><Lazy39/></React.Suspense>
    },
    {
     "refrence_no": 28,
     "page_no": 43,
     "title": "شعار مجموعة الأغر | عرض مجموعة الأغر | فيديو عرض مجموعة الأغر ",
     "content":<Q28/>
    },
    {
     "refrence_no": 29,
     "page_no": 44,
     "title": "مقالة (تبوك بوابة القرن ٢١و٢٢) عكاظ 26 مايو 1998م  ",
     "content":<Q29/>
    },
    {
     "refrence_no": 30,
     "page_no": 45,
     "title": "الدراستان الأولى والثانية اللتان رُفعتا إلى المقام السامي من مجموعة الأغر | مقترح استراتيجي لتنمية الاقتصاد السعودي - 2001 | مبادرات استراتيجية لتحسين اداء الاقتصاد السعودي 2006",
     "content": <Q30/>     },
    {
     "refrence_no": 31,
     "page_no": 46,
     "title": "مقالة (عبدالله وسلطان رسالتان الى المستقبل) عكاظ 21 مايو 2000 م  ",
     "content": <Q31/>
    },
    {
     "refrence_no": 32,
     "page_no": 49,
     "title": "كتاب الرؤية الإسلامية للتنمية في ضوء مقاصد الشريعة ",
     "content":<Q32/>
    },
    {
     "refrence_no": 33,
     "page_no": 49,
     "title": "صورة إجتماع مجموعة الأغر - الجبيلٍ عام 2000  ",

    },
    {
     "refrence_no": 34,
     "page_no": 49,
     "title": "ملخص (إستراتجية المعرفة) التي أعدتها مجموعة الأغر | الملخص التنفيذي (إستراتيجية المعرفة) | صورة اعضاء مجموعة الأغر مع الملك عبدالله - يرحمه الله | فيديو تعريفي عن مجموعة الأغر بلسان أعضاء اللجنة التوجيهية للمجموعة ",
     "Column5": "توحيد اسم ( استراتيجية المعرفة ) "
    },
    {
     "refrence_no": 35,
     "page_no": 50,
     "title": "مجسم حمامة المعرفة",
     "content":<Q35/>
    },
    {
     "refrence_no": 36,
     "page_no": 52,
     "title": "مؤتمر الإقتصاد المعرفي بمدينة جدة في عام 2008  | التقرير النهائي للملتقى | كلمة سمو الأمير خالد الفيصل | كلمة فيصل بن عبدالله | ملخص عن المؤتمر | توجيه الملك عبدالله - يرحمه الله - لوزارة الاقتصاد والتخطيط بتفعيل (استراتيجية المعرفة) 2013| صور من المؤتمر ",
     "content" : <React.Suspense fallback='Loading...' > <Lazy52/> </React.Suspense>
    },
    {
     "refrence_no": 37,
     "page_no": 52,
     "title": "صورة المتطوعين في معرض  (1001 إختراع) المقام على هامش مؤتمر الاقتصاد المعرفي | فيديو الملك عبدالله - يرحمه الله - يزور معرض الحضارات الإسلامية بمقر الأمم المتحدة | فيديو (1001 اختراع ومكتبة الأسرار) | فيديو عن معرض (1001 اختراع)"
    },
    {
     "refrence_no": 38,
     "page_no": 57,
     "title": "دراسة الشميسي | الملخص التنفيذي لمشروع الشميسي | فيديو عرض الملخص التنفيذي لمشروع الشميسي | دراسة وقف الشميسي ( وقف اقرأ ) | العرض المرئي لوقف اقرأ | وقف اقرأ",
     "content":<Page57/>
    },
    {
     "refrence_no": 39,
     "page_no": 59,
     "title": "لوحة إقرأ",
     "content":<Page59/>
    },
    {
     "refrence_no": 40,
     "page_no": 60,
     "title": "صورة الملك سلمان بن عبدالعزيز - يحفظه الله | صورة الكتاب المهدى من الملك سلمان بن عبدالعزيز - يحفظه الله ",
     "content" : <Page60/>
    },
    {
     "refrence_no": 41,
     "page_no": 64,
     "title": "نشيد إروها سيدي | فيديو نشيد إروها سيدي ",
     "content" : <Page64/>
    },
    {
     "refrence_no": 42,
     "page_no": 65,
     "title": "صور الملك عبدالله - يرحمه الله وهو يعاين لأول مرة موقع الجامعة المقترح | كلمة فيصل بن عبدالله (حلم يتحقق) | خطاب فيصل بن عبدالله للملك عبدالله - يرحمه الله - بإنشاء هيئة وطنية عليا للمعرفة والعلوم والتقنية والثقافة ",
     "content" : <Page65/>
    },
    {
     "refrence_no": 43,
     "page_no": 66,
     "title": "ملصق جامعة الملك عبدالله للعلوم والتقنية",
     "content" : <Page66/>
    },
    {
     "refrence_no": 44,
     "page_no": 67,
     "title": "كلمة فيصل بن عبدالله (التعليم للمواطنة) في مركز الملك فيصل للبحوث والدراسات الاسلامية",
     "content" : <Page67/>
    },
    {
     "refrence_no": 45,
     "page_no": 68,
     "title": "فيديو (حلم ملك)",
     "content" : <Page68/>
    },
    {
     "refrence_no": 46,
     "page_no": 69,
     "title": "استراتيجية موهبة",
     "content" : <Page69/>
    },
    {
     "refrence_no": 47,
     "page_no": 70,
     "title": "تطوير مدرسة طيبة في المدينة المنورة | مقترح مشروع دار القلم | مقترح مشروع مركز اقرأ | فيديو دار القلم ",
     "content" : <Page70/>
    },
    {
     "refrence_no": 48,
     "page_no": 71,
     "title": "كلمة فيصل بن عبدالله - وزير التربية والتعليم في منتدى التنافسية",
     "content" : <Page71/>
    },
    {
     "refrence_no": 49,
     "page_no": 72,
     "title": "استراتيجية الرياضة المدرسية | الاستراتيجية الوطنية للرياضه المدرسية NSSS الأجزاء 1-2-3-4",
     "content" : <React.Suspense> <Lazy72/> </React.Suspense>
    },
    {
     "refrence_no": 50,
     "page_no": 76,
     "title": "كتاب (مسيرة التعليم العام )",
     "content" : <Page76/>
    },
    {
     "refrence_no": 51,
     "page_no": 79,
     "title": "عن رسل السلام | تعريف بمشروع رسل السلام | فيديو تعريفي بمشروع رسل السلام | شعار رسل السلام",
     "content" : <Page79/>
    },
    {
     "refrence_no": 52,
     "page_no": 80,
     "title": "برنامج رسل السلام وفيديو إفتتاح حفل التدشين في إستاد جامعة الملك عبدالله للعلوم والتقنية | أغنية حفل التدشين ",
     "content" : <Page80/>
    },
    {
     "refrence_no": 53,
     "page_no": 81,
     "title": "العرض لمركز الكشافة في مدينة الملك عبدالله الإقتصادية | مقترح مركز الكشافه السعودي | فيديو مقترح مركز الكشافة السعودي",
     "content" : <Page81/>
    },
    {
     "refrence_no": 54,
     "page_no": 81,
     "title": "صورة مشاركة المرشدات في تقديم الخدمات خلال موسم الحج  "
    },
    {
     "refrence_no": 55,
     "page_no": 82,
     "title": "استراتيجية مكتب التربية العربي لدول الخليج",
     "content" : <Page82/>
    },
    {
     "refrence_no": 56,
     "page_no": 82,
     "title": "ملخص دراسة (الأسرة المعرفية) | الملخص التنفيذي (دراسة الأسرة المعرفية) | دراسة الأسرة المعرفية"
    },
    {
     "refrence_no": 57,
     "page_no": 83,
     "title": "خطاب فيصل بن عبدالله الى الملك عبدالله - يرحمه الله ",
     "content" : <Page83/>
    },
    {
     "refrence_no": 58,
     "page_no": 83,
     "title": "مجسم إقرأ"
    },
    {
     "refrence_no": 59,
     "page_no": 87,
     "title": "مقال ( 2.5 % من التوفير للتفكير ) | فيديو مركز نور للمعرفة ",
     "content" : <Page87/>
    },
    {
     "refrence_no": 60,
     "page_no": 87,
     "title": "مقال  (يوم الوطن يوم عبدالعزيز) عكاظ  24 سبتمبر 2009 م"
    },
    {
     "refrence_no": 61,
     "page_no": 88,
     "title": "لوحة الملك عبد العزيز( كتاب التوحيد)",
     "content" : <Page88/>
    },
    {
     "refrence_no": 62,
     "page_no": 89,
     "title": "مقالة ( التحول الوطني ) في السعودية .. رؤية ورسالة - فيصل بن عبدالله - الشرق الأوسط 02 يناير 2016 م",
     "content" : <Page89/>
    },
    {
        "refrence_no": 63,
        "page_no": 90,
        "title": "مقالة ( التحول الوطني ) في السعودية .. رؤية ورسالة - فيصل بن عبدالله - الشرق الأوسط 02 يناير 2016 م",
        "content" : <Page89/>
    },

  
   ]

const qrData = primData.map(e=>({...e,url:`/BCReferences?page_no=${e.page_no}`}))

export default qrData