import React from 'react'
import {Image} from 'react-bootstrap'
import p35 from '../../media/Content/Media/25.1.jpg'
import VideoPlayer2 from '../video2/VideoPlayer2'
function Kod32() {
  return (
    <div>
        <p>  
        تشرفتُ بأنْ كانَ لي لقاءٌ في عام ٢٠١٣م، بدعوةٍ كريمةٍ منَ المركزِ الذي سعى إلى تشجيعِ الحوارِ، وتعظيمِ دورِ التعليمِ، وأثرهِ بينَ المجموعاتِ الدينيةِ والثقافيةِ حولَ العالمِ، حيث نظمَّ المركزُمؤتمرَ:(صورة الآخر: التعليم المشترك بين أتباع الأديان والثقافات.. أفضل الممارسات)، إدراكًا لأهميةِ السياساتِ والخبراتِ العمليةِ في مجالِ التعليمِ المشتركِ بين أتباعِ الأديانِ والثقافاتِ، تفعيلًا وتمكينًا وتحفيزًا للحوارِ بينَ القياداتِ الدينيةِ وصانعي السياساتِ.
        </p>
        <Image  src={p35} style={{marginBottom:'10px',width:'100%'}} />
        <VideoPlayer2 src={'https://app.videas.fr/embed/media/cda72524-f4ac-460f-b6d3-d20d839746f2/'} />
    </div>
  )
}

export default Kod32